#toc_container {
	background-color: #fefefe !important;
	float: left;
	margin: 0 30px 25px 0;
	padding: 0;
	position: relative;
	top: 15px;
	width: 320px;
	z-index: 1;

	@include media-breakpoint-down(md) {
		width: 100%;
		//display: table !important;
	}

	&.fixed {
		//position: fixed;
		//top: 15%;
		//width: 320px;

		@include media-breakpoint-up(xl) {
			right: 2%;
		}
		@include media-breakpoint-up(lg) {
			right: 15px;
			position: fixed;
			top: 15%;
			width: 320px;
		}
		@include media-breakpoint-down(lg) {
			top: 27%;
			margin-right: 0;
		}
		@include media-breakpoint-down(sm) {
			//display: none;
		}

		ul {
			&.toc_list {
				@include media-breakpoint-up(lg) {
					max-height: 420px;
					overflow-y: scroll;
				}
			}
		}
	}



	p {
		margin: 0 !important;

		&.toc_title  {
			background-color: $SecondaryColor;
    		font-size: 18px;
    		margin: 0;
    		opacity: 1 !important;
    		padding: 10px 15px;
    		text-align: center;
		}
	}

	ul {
		&.toc_list {
			margin-top: 0 !important;
			padding: 15px;


			li {
    			padding: 0 0 5px;

    			a {	
    				color: #665953;
    				display: block;
    				opacity: 0.8;
    			}

    			&:last-child {
					background: transparent;
					margin: 0;
				}

    			ul {
    				li {
    					&:first-child {
							margin-top: 7px;
						}
    				}
    			}
			}
		}
	}
}


#toc_container + h2 {
	margin-top: 15px !important;
    margin-bottom: 0px !important;
}

#toc_container + h3 {
	margin-top: 15px !important;
    margin-bottom: 0px !important;
}

@include media-breakpoint-down(lg) {
	.toc_mobile {
		opacity: 0;
		cursor: pointer;
		position: fixed;
		top: 20%;
		background: #fefefe;
		padding: 5px;
		border-radius: 5px;
		right: 2%;
		border: 1px solid #665953;
		transition: all 0.3s;

		&.show {
			opacity: 1;
		}
	} 

	.toc_mobile .bar1, .toc_mobile .bar2, .toc_mobile .bar3 {
	  width: 32px;
	  height: 4px;
	  background-color: #665953;
	  margin: 5px 0;
	  transition: 0.4s;
	}

	.toc_mobile.change .bar1 {
	  -webkit-transform: rotate(-46deg) translate(-7px, 6px);
	  transform: rotate(-46deg) translate(-7px, 6px);
	}

	.toc_mobile.change .bar2 {opacity: 0;}

	.toc_mobile.change .bar3 {
	  -webkit-transform: rotate(43deg) translate(-6px, -7px);
	  transform: rotate(43deg) translate(-6px, -7px);
	}

	.toc_mobile.show + #toc_container.fixed {
		//display: none !important;
	}
	.toc_mobile.show.change + #toc_container.fixed {
		//display: table !important;
	}
}