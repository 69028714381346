/*
* Menu Cart
*/
.cart-contents:before {
    font-family:WooCommerce;
    content: "\e01d";
    font-size:28px;
    margin-top:10px;
    font-style:normal;
    font-weight:400;
    padding-right:5px;
    vertical-align: bottom;
}
.cart-contents:hover {
    text-decoration: none;
}
.cart-contents-count {
    color: $White;
    background-color: #2ecc71;
    font-weight: bold;
    border-radius: 10px;
    padding: 1px 6px;
    line-height: 1;
    font-family: Arial, Helvetica, sans-serif;
    vertical-align: top;
}

.woocommerce ul.products li.product .star-rating {
  margin: 0 auto 10px;
}