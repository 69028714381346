/*
=============================================
* Shop Blocks
=============================================
*/
#Shop {
  background-color: $White;

  .items {
    //border-bottom: 1px solid $Septenary;
    padding-top: 50px;   
    padding-bottom: 70px;

    @include media-breakpoint-down(lg) {
      padding-top: 20px; 
    } 

    .home & {
      border-bottom: 1px solid $Septenary;
    }  

    .archive & {
      border-top: 1px solid #d8d8d8;

      @include media-breakpoint-up(lg) {
        padding-top: 70px;
      }   
    }  

    .blog & {
      border-top: 1px solid #d8d8d8;
      //margin-top: 50px;

      @include media-breakpoint-up(lg) {
        //margin-top: 75px;
        padding-top: 70px;
      }   
    }

    .owl-shop {
      margin-bottom: 75px;    
      padding-top: 50px;

      @include media-breakpoint-down(lg) {
        padding-top: 20px; 
      } 

      .item {
        text-align: center;


        .img {
          background-color: $QuinaryColor;
          display: block;
          margin-bottom: 27px;
        }
        h3 {
          color: $PrimaryColor;
          font-family: $FontRegular;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-bottom: 0;
        }
        .thc-content {
          color: $Octonary;
          font-family: $FontRegular;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-bottom: 12px;
          text-align: center;
          text-transform: uppercase;
        }
        .product-review {

          .star-rating {
            display: inline-block;
            font-size: 19px;  
            float: none;
            letter-spacing: 5px;
            margin: 0 auto 10px;
          }

          img {   
            width: auto;
            margin: 0 auto 6px;  
          }
        }
        .price {
          color: $SecondaryColor;
          font-family: $FontBold;
          font-size: 20px;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
        }
      }
    }
  }
}