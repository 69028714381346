// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}  
.search-form .search-submit {
  @extend .btn;
  @extend .btn-primary;
}


.form-control {
  background-color: transparent;
  border: 1px solid $QuaternaryColor;
  box-shadow: none;
  color: $PrimaryColor;
  height: 65px; 


  opacity: 0.6;
  font-family: $FontRegular;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: -0.36px;
  color: $PrimaryColor;

  &:focus {
    background: transparent;
    border: 2px solid $SecondaryColor;
    box-shadow: none;
    outline: 0;
  }
}

.input-group-text {  
  background-color: $SecondaryColor; 
  border: 1px solid  $QuaternaryColor;   
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
  color: $White;
  font-family: $FontRegular;
  font-size: 18px;
  line-height: 1.39;
  letter-spacing: -0.36px;  
  height: 65px; 
}
  
@media (max-width: 575px) {
  .bootstrap-select .btn.dropdown-toggle {
    padding-right: 15px;
  }
}

.dropup .dropdown-toggle:after {
  background-image: url(../images/caret.png);
  border: 0 none;
}
.dropup.show .dropdown-toggle:after {
  transform: rotate(0deg);
}
.dropdown-toggle:after {
  @include transition(all 0.3s);
  background-image: url(../images/caret.png);
  background-repeat: no-repeat;
  background-size: auto;
  width: 24px;
  border: 0 none;
  height: 24px;

  @media (max-width: 575px) {
    width: 35px;  
  }
}
.show .dropdown-toggle:after {
  transform: rotate(180deg);
}