/*
* Archive Title Box 
*/
#ArchiveTitle {
  background-color: #f6f7f9;
  background-image: url(../images/banner-shop2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 70px;
  padding: 40px 0 70px;
  position: relative;
  text-align: center;

  @include media-breakpoint-down(sm) {
    margin-bottom: 40px;
    padding: 15px 0 25px;
  }

  .page &,
  .search &,
  .error404 &,
  .single-videos &,
  .post-type-archive-videos & {
    background-image: url(../images/banner-img.jpg);
  }

  &:before {
    content: '';   
    background-color: rgba(0,0,0,0.2);
    display: block;  
    position: absolute;
    left: 0;
    right: 0;  
    bottom: 0;
    top: 0;

    .blog &, 
    .archive & {
      background-color: rgba(0,0,0,0.05);
    }
  }
  
  .container {
    position: relative;
    z-index: 1;
  }

  .categories {
    margin-bottom: 70px;
    text-align: center;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin: 0 25px 15px;
  
        a {
          color: $PrimaryColor;
          display: block;
          font-family: $Font2SemiBold;
          font-size: 16px;  
          letter-spacing: 0.2px;
          text-transform: uppercase;
  
          &.active,
          &:hover,
          &:focus {
            opacity: 0.6;
          }
        }
      }
    }
  }

  h5 {
    font-family: $FontRegular;
    text-transform: uppercase;
    letter-spacing: 0;  
    margin: 0 0 5px;
  }

  .page-header {
    margin-bottom: 35px;
    text-align: center;

    h1 {
      color: $PrimaryColor;  
      font-size: 45px;
      font-family: $FontBold;
      font-stretch: normal;
      font-style: normal;  
      line-height: 1;
      letter-spacing: normal;
      margin: 0;    

      .page &,
      .search &,
      .error404 &,
      .single-videos &,
      .post-type-archive-videos & {
        color: $White; 
        font-size: 65px;

        @include media-breakpoint-down(md) {
          line-height: 1;
        }

        @include media-breakpoint-down(sm) {
          font-size: 45px;
        }
      }
    }
  }




  .bootstrap-select {
    font-size: 20px;

    .dropdown-toggle .filter-option {
        margin-top: 15px;  
    }

    @include media-breakpoint-up(sm) {
      width: 330px;
    }

    .dropdown-menu {
      li {
        margin: .5rem 0;

        a {
          padding: .25rem 1.5rem .25rem 2.5rem;

          &:after {
            content: '';
            width: 10px;
            height: 10px;
            object-fit: contain;
            border: 1px solid $SenaryColor;
            position: absolute;
            border-radius: 50%;
            left: 17px;
            top: 11px;
          }

          &:hover,
          &:focus {
            background-color: transparent;  
            color: $PrimaryColor;

            &:after {
              background-color: $PrimaryColor;
              border: 1px solid $PrimaryColor;
            }
          }
        }
      }
    }
  }

  .btn {
    background: $White;
    border: 1px solid #dddddd;
    color: $SenaryColor;  
    height: 56px;
    font-size: 20px;
    font-family: $FontRegular;
    line-height: normal;
    padding-left: 25px;
    padding-right: 25px;
    text-transform: none;

    &:focus {
      outline: 0 !important;
      border: 0 none;
      box-shadow: none; 
    }

    &:before {
      color: $PrimaryColor;
      font-size: 20px;
      content: 'Type';
      display: inline-block;
      padding-right: 16px;
    }
  }

  select {
    border: 0 none;
    color: $SenaryColor;
    font-family: $FontRegular;

    &:focus {
      outline: 0;   
    }
  }

  div.dropdown-menu {
    padding: 0;

    &.show {
      border: 0 none;
      box-shadow: 0 5px 24px 0 rgba(0, 0, 0, 0.1);
      padding: 0.75rem 0;
    }


    li {
      &:last-child {
        margin-bottom: 0;
      }

      a {
        font-family: $FontRegular;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $SenaryColor;

        &.dropdown-item {  

          &.active {
            background-color: transparent;  
            color: $SenaryColor;
          }
        }
      }
    }
  }

}


.archive,
.blog {
	.wrap {

    @include media-breakpoint-up(xl) {
      .row {
        margin-left: -27px;
        margin-right: -27px;

        .col-lg-4 {
          padding-left: 27px;
          padding-right: 27px;
        }
      }
    }

   
		.content {
			article {
        margin: 0 0 55px;

        @media (max-width: 575px) {
          box-shadow: 0 0 30px 0 rgba(0,0,0,.1);
          margin: 0 0 15px;
          padding: 1rem;
        }

        img {
          margin-bottom: 20px;

          @include media-breakpoint-down(sm) {   
            width: 100%
          }
        }
        .type {
          color: $TertiaryColor;
          font-family: $Font2SemiBold;
          font-size: 14px;
          letter-spacing: 0.18px;
          margin-bottom: 20px;
          text-transform: uppercase;
        }
				h3 {
          color: $PrimaryColor;
          font-family: $FontBold;
          font-size: 30px;
          font-stretch: normal;
          font-style: normal;
          //line-height: 0.97;
          line-height: 1;
          letter-spacing: normal;     
          margin-bottom: 16px;

          @include media-breakpoint-up(sm) {   
            //text-overflow: ellipsis;
            //overflow: hidden;
            //white-space: nowrap;  
          }
        }   

        p {
          color: $SenaryColor; 
          margin-bottom: 10px;
        }
        .ByAuthor {
          color: $PrimaryColor;
          font-family: $Font2LightItalic;
          font-size: 14px;
        }
			}
		}
	}
}        



.error404,
.archive,
.search {
  .search-form {
    input {
      height: 51px;
      width: 100%;
    }
    button {
      margin-top: 1rem;
    }
  }
}  

.search {
  .content {
    article {
      //border-bottom: 1px solid #dfdcd4;
      margin-bottom: 1rem;    
      padding-bottom: 1rem;
    }
  }
}


