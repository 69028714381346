﻿html {
	scroll-behavior: smooth;
}
body {
	color: $PrimaryColor;
	font-family: $FontRegular;
	font-size: 18px !important;  

	&.home,
	&.error404 {
		background-color: $QuinaryColor;
	}
}
    

a {
	@include transition(all 200ms ease-in);
	color: $SecondaryColor;
	outline: 0 !important;
	
	&:hover,
	&:active,
	&:focus {
		color: $SecondaryColor;
		text-decoration: none;
		outline: 0 !important; 
		-webkit-tap-highlight-color: rgba(0,0,0,0);
	} 
   
	&:before,
	&:after {  
		@include transition(all 200ms ease-in);
	} 
}


.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	margin-bottom: 1rem;
}

h2 {
	color: $PrimaryColor;

	&.title {
        color: $PrimaryColor; 
		font-family: $FontBold;
        font-size: 45px;
        font-stretch: normal;  
        font-style: normal;
        letter-spacing: normal;
        line-height: normal;
    	text-align: center;
	}

	&.section-title {
        font-family: $FontRegular;
	    font-size: 40px;
	    margin: 0;
	    font-weight: normal;
	    font-stretch: normal;
	    font-style: normal;
	    line-height: 2;
	    letter-spacing: normal;
	}

	@include media-breakpoint-down(sm) {
	    line-height: 1 !important;
	}
}



h3 {
	font-size: 30px;

	@include media-breakpoint-down(sm) {
	    line-height: 1 !important;
	}
}

h4 {   
	&.title {
        color: $TertiaryColor;   
		font-family: $Font2SemiBold;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.2px;
        line-height: normal;
        margin-top: 60px;
    	text-align: center;
    	text-transform: uppercase;
	}
}

h5 {   
	&.title {
        color: $TertiaryColor; 
		font-family: $Font2SemiBold;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.2px;
        line-height: normal;
        margin-top: 60px;
    	text-align: center;
    	text-transform: uppercase;
	}
}


@include media-breakpoint-down(sm) {
  	.heading {
		h2 {
			font-size: 36px !important;
		}
	}

	h2 {
		&.title {
			font-size: 36px !important;
		}
	}
}


p {
  	//color: $SenaryColor; 
	&:last-child {
	  	margin-bottom: 0; 
	}
}

.container { 
	@include media-breakpoint-up(xl) {
		max-width: 1190px;
	}
}    

.page-header {
	h1 {
		span {
			.error404 & {  
			    font-size: 120px;
			}
		}
	}
}