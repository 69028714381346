.breadcrumbs {
	background-color: $White;
	color: rgba(102, 89, 83, 0.14);
	padding: 10px 0 12px;

	a {
		font-family: $FontRegular;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.2px;
		color: rgba(102, 89, 83, 0.49);
		padding-right: 6px;
	}
	.breadcrumb_last {
		font-family: $FontRegular;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.2px;
		color: rgba(102, 89, 83, 0.8);
		padding-left: 6px;
		padding-right: 6px;
	}
}