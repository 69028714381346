/*
=============================================
* Header Area
=============================================
*/
#header {
  @include transition(all 0.7s);
  background-color: $White;
  border-bottom: 1px solid #dfdcd4;
  top: 0; 
  padding: 15px 0 32px;
  position: fixed;
  width: 100%;  
  z-index: 999;
  
  @include media-breakpoint-up(lg) {
    position: fixed;
    width: 100%;
  }

  @include media-breakpoint-down(md) {
    padding: 10px 0 15px;
  }
  
  .home &,
  .error404 & {  
    background-color: $QuinaryColor;
    border-bottom: 0 none;  
  }
     
  &.fixed {      
    background-color: $White;
    border-bottom: 1px solid #dfdcd4;
    padding: 5px 0 10px;
  }

  .container {
    position: relative;
  }

  .logo {
   // padding: 15px 0 33px;
    display: block;
  }

  .align-self-end {
    margin-top: 7px;
  }

  nav {
    ul {
      li {
        a {
          font-family: $FontRegular;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $PrimaryColor;
          padding-left: 15px;
          padding-right: 15px;  
          height: auto;

          &:hover,
          &:focus {
            background-color: transparent;
            font-family: $FontBold;
            color: $TertiaryColor;
          }  
        }

        &:first-child a {
          padding-left: 0;
        }

        &:last-child a {
          padding-right: 0;
        }
      }
    }

    &.menu-right {
      ul {
        li {
          a {
            &:hover {  
              font-family: $FontBold;
              color: $TertiaryColor;
            }
          }
        }
      }
    }
  }
}



@include media-breakpoint-down(md) {
  .main-nav {
    width: 100%;

    .mega-menu-toggle {
      display: inline-block;   
      position: absolute;
      right: 0;
      top: -40px;
    }
    
    #mega-menu-primary_navigation {
      top: 10px;
    }
  }
}
