#CounterCategories,
#CounterShop {
	color: $SenaryColor;
	font-family: $Font2Regular;
	font-size: 25px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	position: relative;

	@include media-breakpoint-down(sm) {
		text-align: center;
	}

	&.Counter {
	    right: 50px;  

	    @include media-breakpoint-down(sm) {
			right: 0;  
		}
	} 
}   

.owl-carousel {
	.owl-nav {
		position: absolute;
		top: -55px;
		right: 0;
		width: 170px;

		@include media-breakpoint-down(sm) {
			top: -30px;
			width: 200px;
			left: 0;
			margin: 0 auto;
		}

		button {
			background-repeat: no-repeat !important;
		    background-size: contain !important;
    		height: 30px;
			min-width: 35px;

			&:focus,
			&:focus-within {
				border: none;
				outline: 0;
			}

			&.disabled {
				opacity: 0.45;
			}

			&.owl-next {
    			background-image: url(../images/ArrowRight.png);
    			background-position: right -2px center;
    			right: 0;
    			position: absolute;
			}
			&.owl-prev {
				background-image: url(../images/ArrowLeft.png);
				//margin-right: 90px;
			}
		}
	}
}   