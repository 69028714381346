.wpcf7-form-control-wrap {
	input {
		@extend .form-control;
		height: 51px;
	}
	textarea {
		@extend .form-control;
		min-height: 285px;

		@media (max-width: 575px) {
		    margin-bottom: 25px;
			min-height: 128px;
		}
	}
}

.wpcf7-form-control {
	&.wpcf7-submit {
		@extend .btn;
		@extend .btn-primary;
	}
}

div.wpcf7 .ajax-loader {
	display: none;
}