#ProductEffects {
	.heading {
		@include media-breakpoint-up(md) {
			margin-bottom: 30px;
	    }

		h2 {
			color: $PrimaryColor;
			font-family: $FontRegular;
			font-size: 40px;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;

			@include media-breakpoint-up(md) {
				line-height: 2;
		    }
		}

		.effects-info {
			position: relative;

			p {
				@include media-breakpoint-up(lg) {
					text-align: right;
			    }

				.info-icon {
					background-image: url(../images/info.png);
					background-repeat: no-repeat;
	    			background-size: contain;
					content: '';
					cursor: pointer;
					display: inline-block;
					height: 22px;
					margin-left: 7px;
					position: relative;   
					top: 3px;  
					width: 22px;  
				}
			}  
			.info-tooltips {
				@include transition(all 0.3s);
				background-color: $White;
			    box-shadow: 0 5px 24px 0 rgba(0, 0, 0, 0.1);
			    color: rgba(102, 89, 83, 0.84);
			    font-size: 16px;
			    margin-left: auto;
			    font-family: $FontRegular;
    			opacity: 0;
			    padding: 24px;
			    position: absolute;
    			right: 0;
			    text-align: left;
				width: 270px;
    			z-index: 1;

    			&.hover {
    				opacity: 1;
    			}

    			&:before {
    			  	content: "";
    				display: inline-block;
				    width: 0;
				    height: 0;
				    border-left: 10px solid transparent;
				    border-right: 10px solid transparent;
				    border-bottom: 10px solid $White;
				    position: absolute;
				    right: 15px;
				    top: -10px;
				}
			}
		}
	}
   
	.StatusBar {


  		@include media-breakpoint-up(lg) {
  			.row {
  				margin-left: -38px;
  				margin-right: -38px;
  			}
  			.col-lg-4 {
  				padding-left: 38px;
  				padding-right: 38px;
  			}
  		}   

		h3 {
			color: $PrimaryColor;
			font-family: $FontBold;
			font-size: 30px;
			font-stretch: normal;
			font-style: normal;
			line-height: 0.97;
			letter-spacing: normal;
			margin-bottom: 20px;

			@include media-breakpoint-down(md) {
				margin-top: 30px;
		    }
		}

		.item {
			p {
				margin-bottom: 12px;
			}
			.progress {
				@include border-radius(2px);
				background-color: $QuinaryColor;    
				height: 16px;
				margin-bottom: 30px;

				.progress-bar {
					background-color: $SecondaryColor;
				}
			}

			&:last-child {
				.progress {
					margin-bottom: 0px;
				}
			}
		}
	}
}