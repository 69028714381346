#mega-menu-wrap-primary_navigation {
  background-color: transparent;
}
// Primary Navigation (Main Menu)
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation>li.mega-menu-item {
  padding-bottom: 7px;
}
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation>li.mega-menu-item>a.mega-menu-link {
  font-family: $FontRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $PrimaryColor;
  padding-left: 15px;
  padding-right: 15px;  
  height: auto;

  @include media-breakpoint-only(lg) {
    padding-left: 15px !important;
    padding-right: 15px !important; 
  }
}
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation>li.mega-menu-item.mega-current-menu-item>a.mega-menu-link {
  background-color: transparent;
  font-family: $FontBold;
  color: $TertiaryColor;
}
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation>li.mega-menu-item.mega-toggle-on>a.mega-menu-link {
  background-color: transparent;
  font-family: $FontBold;
  color: $TertiaryColor;
}
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation>li.mega-menu-item>a.mega-menu-link:hover {
  background-color: transparent;
  font-family: $FontBold;
  color: $TertiaryColor;
}   
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation>li.mega-menu-megamenu>ul.mega-sub-menu {
  @include transition(all 0.3s);
  background-image: url(../images/leaf-00.png);
  background-color: $White;
  background-repeat: no-repeat;
  //background-position: right top 35%;
  background-position: right bottom 15%;
  background-size: auto;
  border-bottom: 1px solid #dfdcd4;
  border-top: 1px solid #dfdcd4;
  padding: 60px 0 170px;
  position: fixed;  
  top: 108px;     
  @include media-breakpoint-up(xl) {
    min-height: 540px;   
  }
  .menu-fixed & {
    top: 75px;   
  }
}
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation li.mega-menu-megamenu>ul.mega-sub-menu>li.mega-menu-row {
  //width: 1190px; // Remove this temporary for fullwidth 6 column on grid. 
  padding-left: 15px;
  padding-right: 15px;    
  margin: 0 auto;   
  float: none;
}
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation>li.mega-menu-megamenu>ul.mega-sub-menu>li.mega-menu-item h4.mega-block-title, 
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation>li.mega-menu-megamenu>ul.mega-sub-menu li.mega-menu-column>ul.mega-sub-menu>li.mega-menu-item h4.mega-block-title {
  font-family: $FontBold;
  font-size: 26px;
  font-stretch: normal;
  font-style: normal;   
  line-height: normal;
  letter-spacing: normal;
  color: $PrimaryColor;  
  margin-bottom: 30px;

  @include media-breakpoint-only(lg) {
    font-size: 20px;
  } 
}
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation>li.mega-menu-megamenu>ul.mega-sub-menu li.mega-menu-column>ul.mega-sub-menu>li.mega-menu-item h4.mega-block-title a {
  font-family: $FontBold;
  font-size: 26px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $PrimaryColor;  

  &:hover,
  &:focus {
  	color: $TertiaryColor;  
  }
}
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation li.mega-menu-megamenu>ul.mega-sub-menu>li.mega-menu-row .mega-menu-column>ul.mega-sub-menu>li.mega-menu-item ul {
  padding-left: 0;
}
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation li.mega-menu-megamenu>ul.mega-sub-menu>li.mega-menu-row .mega-menu-column>ul.mega-sub-menu>li.mega-menu-item ul li {
  list-style: none;
  margin-bottom: 10px;
}
#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation li.mega-menu-megamenu>ul.mega-sub-menu>li.mega-menu-row .mega-menu-column>ul.mega-sub-menu>li.mega-menu-item ul li a {
  font-family: $FontRegular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $PrimaryColor;
}

#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation li.mega-menu-megamenu>ul.mega-sub-menu>li.mega-menu-row .mega-menu-column>ul.mega-sub-menu>li.mega-menu-item ul li a:hover {
  font-family: $FontBold;
  color: $TertiaryColor;
}

#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation>li:first-child.mega-menu-item>a.mega-menu-link {
	@include media-breakpoint-up(lg) {
		padding-left: 0;
	}
}

@include media-breakpoint-down(lg) {
	#mega-menu-wrap-primary_navigation .mega-menu-toggle {
		display: none;
	}   
	#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation {
		padding-top: 0;
	}
	#mega-menu-wrap-primary_navigation .mega-menu-toggle + #mega-menu-primary_navigation {
		
	}

	#mega-menu-wrap-primary_navigation #mega-menu-primary_navigation > li.mega-menu-item > a.mega-menu-link {
		padding-left: 0;
		padding-right: 0;
	}
}





// Primary Navigation 2 (Right Menu)
@media (min-width: 992px) {
  #mega-menu-wrap-primary2_navigation {
      background: transparent;
  }
}
#mega-menu-wrap-primary2_navigation #mega-menu-primary2_navigation>li.mega-menu-item {
  padding-bottom: 7px;
}
#mega-menu-wrap-primary2_navigation #mega-menu-primary2_navigation>li.mega-menu-item>a.mega-menu-link {
  font-family: $FontRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $PrimaryColor;
  padding-left: 15px;
  padding-right: 15px;  
  height: auto;

  @include media-breakpoint-only(lg) {
    padding-left: 15px !important;
    padding-right: 15px !important; 
  }
}
#mega-menu-wrap-primary2_navigation #mega-menu-primary2_navigation>li.mega-menu-item.mega-current-menu-item>a.mega-menu-link {
  background-color: transparent;
  font-family: $FontBold;
  color: $TertiaryColor;
}
#mega-menu-wrap-primary2_navigation #mega-menu-primary2_navigation>li.mega-menu-item.mega-toggle-on>a.mega-menu-link {
  background-color: transparent;
  font-family: $FontBold;
  color: $TertiaryColor;
}
#mega-menu-wrap-primary2_navigation #mega-menu-primary2_navigation>li.mega-menu-item>a.mega-menu-link:hover {
  background-color: transparent;
  font-family: $FontBold;
  color: $TertiaryColor;
}   
#mega-menu-wrap-primary2_navigation #mega-menu-primary2_navigation>li.mega-menu-megamenu>ul.mega-sub-menu {
  @include transition(all 0.3s);
  background-image: url(../images/leaf-00.png);
  background-color: $White;
  background-repeat: no-repeat;
  //background-position: right top 35%;
  background-position: right bottom 15%;
  background-size: auto;
  border-bottom: 1px solid #dfdcd4;
  border-top: 1px solid #dfdcd4;
  padding: 60px 0 170px;
  position: fixed;  
  top: 108px;     
  @include media-breakpoint-up(xl) {
    min-height: 540px;   
  }
  .menu-fixed & {
    top: 75px;   
  }
}
#mega-menu-wrap-primary2_navigation #mega-menu-primary2_navigation li.mega-menu-megamenu>ul.mega-sub-menu>li.mega-menu-row {
  //width: 1190px; // Remove this temporary for fullwidth 6 column on grid. 
  padding-left: 15px;
  padding-right: 15px;    
  margin: 0 auto;   
  float: none;
}
#mega-menu-wrap-primary2_navigation #mega-menu-primary2_navigation>li.mega-menu-megamenu>ul.mega-sub-menu>li.mega-menu-item h4.mega-block-title, 
#mega-menu-wrap-primary2_navigation #mega-menu-primary2_navigation>li.mega-menu-megamenu>ul.mega-sub-menu li.mega-menu-column>ul.mega-sub-menu>li.mega-menu-item h4.mega-block-title {
  font-family: $FontBold;
  font-size: 26px;
  font-stretch: normal;
  font-style: normal;   
  line-height: normal;
  letter-spacing: normal;
  color: $PrimaryColor;  
  margin-bottom: 30px;

  @include media-breakpoint-only(lg) {
    font-size: 20px;
  } 
}
#mega-menu-wrap-primary2_navigation #mega-menu-primary2_navigation>li.mega-menu-megamenu>ul.mega-sub-menu li.mega-menu-column>ul.mega-sub-menu>li.mega-menu-item h4.mega-block-title a {
  font-family: $FontBold;
  font-size: 26px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $PrimaryColor;  

  &:hover,
  &:focus {
    color: $TertiaryColor;  
  }
}
#mega-menu-wrap-primary2_navigation #mega-menu-primary2_navigation li.mega-menu-megamenu>ul.mega-sub-menu>li.mega-menu-row .mega-menu-column>ul.mega-sub-menu>li.mega-menu-item ul {
  padding-left: 0;
}
#mega-menu-wrap-primary2_navigation #mega-menu-primary2_navigation li.mega-menu-megamenu>ul.mega-sub-menu>li.mega-menu-row .mega-menu-column>ul.mega-sub-menu>li.mega-menu-item ul li {
  list-style: none;
  margin-bottom: 10px;
}
#mega-menu-wrap-primary2_navigation #mega-menu-primary2_navigation li.mega-menu-megamenu>ul.mega-sub-menu>li.mega-menu-row .mega-menu-column>ul.mega-sub-menu>li.mega-menu-item ul li a {
  font-family: $FontRegular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $PrimaryColor;
}

#mega-menu-wrap-primary2_navigation #mega-menu-primary2_navigation li.mega-menu-megamenu>ul.mega-sub-menu>li.mega-menu-row .mega-menu-column>ul.mega-sub-menu>li.mega-menu-item ul li a:hover {
  font-family: $FontBold;
  color: $TertiaryColor;
}

#mega-menu-wrap-primary2_navigation #mega-menu-primary2_navigation>li:first-child.mega-menu-item>a.mega-menu-link {
  @include media-breakpoint-up(lg) {
    padding-left: 0;
  }
}

@include media-breakpoint-down(lg) {
  #mega-menu-wrap-primary2_navigation .mega-menu-toggle {
    display: none;
  }   
  #mega-menu-wrap-primary2_navigation #mega-menu-primary2_navigation {
    padding-top: 0;
  }
  #mega-menu-wrap-primary2_navigation .mega-menu-toggle + #mega-menu-primary2_navigation {
    
  }

  #mega-menu-wrap-primary2_navigation #mega-menu-primary2_navigation > li.mega-menu-item > a.mega-menu-link {
    padding-left: 0;
    padding-right: 0;
  }
}







  
.mega-sub-menu .mega-block-title a {
  font-family: $FontBold !important;
  font-size: 26px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #665953 !important;

  @include media-breakpoint-only(lg) {
    font-size: 20px !important;
  }  

  &:hover,   
  &:focus {
    font-family: $FontBold !important;
    color: $TertiaryColor !important;  
  }  
}

.mega-sub-menu li ul {
  padding: 0;
  list-style: none;
}
.mega-sub-menu li ul li {
  margin: 10px 0;
  padding-bottom: 10px;
  padding-left: 20px;
  position: relative;

  &:before { 
    content: "";
    clear: both;
    display: inline-block;
    background-image: url('../images/logo-icon2.png');
    background-repeat: no-repeat;
    background-position: top;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0px;
    background-size: contain;
    top: 6px;
  }
}

.mega-sub-menu li ul li a {
  font-family: $FontRegular !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  font-stretch: normal !important;  
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: $PrimaryColor !important;

  @include media-breakpoint-only(lg) {
    font-size: 15px !important;
  } 

  &:hover,   
  &:focus {
    font-family: $FontBold !important;
    color: $TertiaryColor !important;  
  }  
}     




#mega-menu-wrap-mobile_navigation .mega-menu-toggle { display: none !important; }
#mega-menu-wrap-mobile_navigation .mega-menu-toggle + #mega-menu-mobile_navigation {
  display: block !important;
}
#mega-menu-wrap-mobile_navigation #mega-menu-mobile_navigation > li.mega-menu-item:first-child > a.mega-menu-link {
  border-top: 2px solid #fff;
}
#mega-menu-wrap-mobile_navigation #mega-menu-mobile_navigation>li.mega-menu-item {  
  display: block !important;  
}
#mega-menu-wrap-mobile_navigation #mega-menu-mobile_navigation > li.mega-menu-item > a.mega-menu-link {
    border-radius: 0;
    border: 0;
    margin: 0;
    line-height: normal;
    height: auto;
    padding: .5rem .125rem;
    background: transparent;
    color: #fff;
    font-size: 18px;
    border-bottom: 1px solid #fff;
    text-align: center;
    vertical-align: middle;
}

#mega-menu-wrap-mobile_navigation #mega-menu-mobile_navigation > li.mega-menu-megamenu > ul.mega-sub-menu {
  padding: 0;
}
#mega-menu-wrap-mobile_navigation #mega-menu-mobile_navigation li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row .mega-menu-column > ul.mega-sub-menu > li.mega-menu-item {
  background: #58864c;
}
#mega-menu-wrap-mobile_navigation #mega-menu-mobile_navigation > li.mega-menu-megamenu > ul.mega-sub-menu form label {
  margin-bottom: 0;

  .search-field {
    color: $White;
    background-color: transparent !important;
    font-size: 18px !important;
  }

  .search-field::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $White;
  }
  .search-field::-moz-placeholder { /* Firefox 19+ */
     color: $White;
  }
  .search-field:-ms-input-placeholder { /* IE 10+ */
    color: $White;
  }
  .search-field:-moz-placeholder { /* Firefox 18- */
    color: $White;
  }
}
#mega-menu-wrap-mobile_navigation #mega-menu-mobile_navigation > li.mega-menu-megamenu > ul.mega-sub-menu form .search-submit { 
  right: 0!important;
  top: -1px!important;
  height: 29px !important;
  width: 29px !important;
}  
#mega-menu-wrap-mobile_navigation #mega-menu-mobile_navigation > li.mega-menu-flyout ul.mega-sub-menu li.mega-menu-item a.mega-menu-link {
  background: #58864c;
  color: $White;
  font-size: 16px;
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #dfdcd4;
}

