.woocommerce ul.products li.product .onsale { 
  @include border-radius(0);
  background-color: rgba(102, 89, 83, 0.98);
  color: #fdf8ef;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  margin: 0;
  min-height: 33px;
  min-width: 65px;
  padding: 8px 0;
  text-align: center;
}
.woocommerce ul.products li.product, .woocommerce-page ul.products li.product {
	text-align: center;

	img {
      background-color: $QuinaryColor;
      display: block;
      margin-bottom: 27px;
    }

    .woocommerce-loop-product__title {
      color: $PrimaryColor;
      font-family: $FontRegular !important;
      font-size: 1.2em;
      @include media-breakpoint-up(md) {
        font-size: 30px !important;
      }
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;     
      letter-spacing: normal;
      padding: 0;
    }
    .thc-content {
      color: $Octonary;
      font-family: $FontRegular;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-bottom: 12px;
      text-align: center;
      text-transform: uppercase;
    }
    .product-review {
      img {
        width: auto;
        margin: 0 auto 6px;
      }
    }

    .price {
      color: $SecondaryColor;
      font-family: $FontBold;
      font-size: 20px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
}

.woocommerce-product-rating {
  .count-loop {
    position: relative;
    display: inline-block;
    bottom: 13px;
    margin-left: 7px;
    font-family: $FontRegular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $PrimaryColor;
  }
}


.woocommerce-page ul.products li.product.outofstock .stock.out-of-stock, 
.woocommerce ul.products li.product.outofstock .stock.out-of-stock {
  color: rgba(245, 105, 63, 0.68);
  font-size: 20px;
  line-height: normal;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: $FontRegular;
}

.woocommerce ul.products li.product.outofstock .price,
.woocommerce-page ul.products li.product.outofstock .price {
  display: none;
}