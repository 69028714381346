// Grid settings
$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
$sidebar-xl-columns:    3;
$sidebar-lg-columns:    4;
$content-columns:    9;

// Theme Colors
$Black: 				#000000;
$White:					#FFFFFF;

$PrimaryColor:			#665953;
$SecondaryColor:		#7ca471;
$TertiaryColor:			#f5693f;
$QuaternaryColor:		#979797;
$QuinaryColor:			#fdf8ef;
$SenaryColor:			#a39d94;
$Septenary:				#d8d8d8;


$Octonary:				#969088;
  
// Theme Font Family
$FontRegular:			'Circe';
$FontBold:				'CirceBold';
$FontLight:				'CirceLight';

$Font2Regular:			'SofiaPro';
$Font2SemiBold:			'SofiaProSemiBold';
$Font2LightItalic:		'SofiaProLightItalic';
$Font2MediumItalic:		'SofiaProMediumItalic';

// BG Color
$theme-colors: (
  primary: $PrimaryColor,
  secondary: $SecondaryColor,
  tertiary: $TertiaryColor
);


  




  











$font-thin:				'latothin';
$font-light:			'latolight';
$font-heavy:			'latoheavy';
$font-regular:			'latoregular';
$font-medium:			'latomedium';
$font-bold:				'latobold';
$font-semibold:			'latosemibold';
$font-black:			'latoblack';




$primary-color: 		#5b9638;
$secondary-color: 		#232323;
$tertiary-color: 		#161616;
$quaternary-color: 		#919191;
$quinary-color: 		#f6ff00;
$senary-color:			#cfcfcf;
$septenary-color:		#959595;
$octonary-color:		#f2f2f2;
$nonary-color:			#70b857;
$denary-color:			#e2e2e2;


$btn-transparent-color:    	$White;
$btn-transparent-bg:     	transparent;
$btn-transparent-border:   	$White; 



 

