/*
* Review Rating 
*/
.woocommerce .star-rating {
  color: #f5693f;
  height: 19px;
  width: 120px;
}
.woocommerce ul.products li.product .star-rating {
  font-size: 19px;
  letter-spacing: 5px;
}
.woocommerce .woocommerce-product-rating .star-rating {
  letter-spacing: 3px;
  margin: 0;
  font-size: 19px;  
  letter-spacing: 5px;
  float: none;
  display: inline-block;
}
.woocommerce div.product .woocommerce-product-rating .woocommerce-review-link { 
  display: inline-block;
  position: relative;
  bottom: 3px;
  margin-left: 5px;
}
.woocommerce div.product .woocommerce-product-rating .ProductRespond {
  bottom: 5px;
  border-bottom: 2px solid $SecondaryColor;
  font-size: 16px;
  margin-left: 25px; 
  position: relative;
}
.woocommerce .woocommerce-product-rating .base-on-review {
  position: relative;
  margin-left: 15px;
  color: $PrimaryColor;   
  font-size: 16px;  
  line-height: normal;
  bottom: 3px;
}  
.woocommerce .woocommerce-Reviews .wrap-comment-btn {
  margin-bottom: 60px;
  margin-top: 23px;
  text-align: center;
}
.woocommerce .woocommerce-Reviews .wrap-comment-btn .btn {
  color: $SecondaryColor;
  font-size: 15px;
  font-family: $FontBold;
  border: 2px solid $SecondaryColor;     
  background: $QuinaryColor;
  text-transform: uppercase;
  border-radius: 2px;
  padding: 1px 0 0;
  height: 44px;
}
.woocommerce .woocommerce-Reviews .wrap-comment-btn .btn:hover,
.woocommerce .woocommerce-Reviews .wrap-comment-btn .btn:focus {
  background: $SecondaryColor;
  color:  $White;
}


.woocommerce {
	p {
		&.stars a {
		    height: 22PX;
		    width: 22PX;
		    font-size: 22PX;

		    &:before {
    			color: $TertiaryColor;
		    }
		}
	}
}


#comments {
	.review {
		margin-bottom: 35px;
	}
}    