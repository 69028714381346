// Import All woocommerce css
@import "plugins/woocommerce/products";
@import "plugins/woocommerce/pagination";
@import "plugins/woocommerce/single";
@import "plugins/woocommerce/reviews";
@import "plugins/woocommerce/titlebox";
@import "plugins/woocommerce/effects";
@import "plugins/woocommerce/orderby";
@import "plugins/woocommerce/menucart";
@import "plugins/woocommerce/variation";
@import "plugins/woocommerce/cart-and-checkout";



/*
* Result Count
*/
.woocommerce .woocommerce-result-count {
  font-family: $FontRegular;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $SenaryColor;
  margin: 0;
}

.woocommerce span.onsale {
	min-height: 2em;                        
    min-width: 5em;
    padding: 0 2px;
    font-size: 1em; 
    border-radius: 0;
    line-height: 3;
    font-weight: normal;
    top: 0;  
    left: 0;
    margin: 0;
    background-color: rgba(102,89,83,.98);  
}

   



