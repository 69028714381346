.pushy-open-left,
.pushy-open-right {
  .site-overlay {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.pushy {
  background-color: $SecondaryColor;
  width: 280px;

  @media (min-width: 576px) {
    width: 320px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    &:first-child {
      margin: 1rem 1rem 0;
    }

    button {
      color: $White;
      padding: 1rem;
 
      &:after {
        color: $White;
      }
    }

    li {
      a { 
        border-bottom: 1px solid $White;
        color: $White;
        padding: .5rem 0.125rem;
        text-align: center;
      } 

      &:first-child {
        a {
          border-top: 2px solid $White;
        }
      }

      .sub-menu {
        @include transition(all 0.075s);
        height: 0;
        text-indent: 1rem;
        visibility: hidden;

        li {
          a {
            @include transition(all 0.075s);
            font-size: 0.9rem;
            padding: 0 1rem;
          }
        }

        &.open {
          height: 100%;
          visibility: visible;

          li {
            a {
              padding: 0.5rem 1rem;
            }
          }
        }
      }

      &.menu-item-has-children {
        > a {
          cursor: pointer;
          position: relative;

          &:after {
            @include transition(all 0.3s);
            @include translate(0, -50%);
            color: $White;
            content: "\f067";
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            position: absolute;
            right: 12px;
            text-rendering: auto;
            top: 50%;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }

          &.open {
            &:after {
              content: "\f068";
            }
          }
        }
      }
    }
  }

  .pushy-content {
    padding-top: 2.5rem;
  }
}

.pushy-right {
  -webkit-transform: translate3d(280px, 0, 0);
  -ms-transform: translate3d(280px, 0, 0);
  transform: translate3d(280px, 0, 0);

  @media (min-width: 576px) {
    -webkit-transform: translate3d(320px, 0, 0);
    -ms-transform: translate3d(320px, 0, 0);
    transform: translate3d(320px, 0, 0);
  }
}

#toggle span, 
#toggle span:after,
#toggle span:before {
  width: 100%;
  height: 4px;
  background-color: $SecondaryColor;
  transition: all 0.3s;
  backface-visibility: hidden;
} 

/* Important styles */
#toggle {
  @include translate(0, -65%);
  @include transition(all .3s);
  border: 0 none; 
  color: $White; 
  display: block;
  height: 24px;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 65%; 
  width: 30px;

  &:before {
    bottom: -35px;
    content: attr(before);
    position: absolute;
  }

  &:after {
    content: attr(after);
    display: none;
    position: absolute;
  }

  &:hover,
  &:focus {
    background-color: transparent;
    color: $primary-color;
    opacity: 1;
  }

  span {
    display: block;
    margin: 0 0 15px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 10px;
    } 
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 20px;
    } 
  }

  &.on { 
    color: $White;
    position: fixed;
    top: 35px;
    z-index: 9999;

    &:before {
      display: none;
    }

    &:after {
      display: block;
    }

    span {
      background-color: transparent;
      
      &:after,
      &:before {
        background-color: $White;
      }
    } 
  }
}


/* on activation */
#toggle.on span:before {
  transform: rotate(45deg) translate(0, 0);
}
#toggle.on span:after {
  transform: rotate(-45deg) translate(7px, -7px);
}
#toggle.on + #menu {
  opacity: 1;
  visibility: visible;
}
