@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.woff2') format('woff2'),
         url('../fonts/fontawesome-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Circe';
    src: url('../fonts/Circe-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'CirceBold';
    src: url('../fonts/Circe-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'CirceLight';
    src: url('../fonts/Circe-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SofiaPro';
    src: url('../fonts/SofiaProRegular.woff2') format('woff2'),
         url('../fonts/SofiaProRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SofiaProSemiBold';
    src: url('../fonts/SofiaProSemiBold.woff2') format('woff2'),
         url('../fonts/SofiaProSemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SofiaProLightItalic';
    src: url('../fonts/SofiaProLight-Italic.woff2') format('woff2'),
         url('../fonts/SofiaProLight-Italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SofiaProMediumItalic';
    src: url('../fonts/SofiaProMedium-Italic.woff2') format('woff2'),
         url('../fonts/SofiaProMedium-Italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

