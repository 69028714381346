/*
=============================================
* Homepage Popular Article Area
=============================================
*/
#PopularArticles {
  background-color: $White;
  padding-top: 40px;

  @include media-breakpoint-up(lg) {
    padding-top: 70px;
  } 

  .items {
    border-bottom: 1px solid $Septenary;
    padding-top: 50px;
    padding-bottom: 75px;

    @include media-breakpoint-down(lg) {
      padding-bottom: 50px;
      padding-top: 20px;
    }  

    .col-lg-5 {
      @include media-breakpoint-up(lg) {
        max-width: 46.9%;
        flex: 0 0 46.9%;
        -ms-flex: 0 0 46.9%;
      }
    }  

    .col-lg-7 {
      @include media-breakpoint-up(lg) {
        max-width: 53.1%;
        flex: 0 0 53.1%;
        -ms-flex: 0 0 53.1%;
      }
    }  

    h2 {
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }
   
    .featured {

      @include media-breakpoint-up(xl) {
        max-width: 515px;
      }

      @include media-breakpoint-down(md) {
        box-shadow: 0 0 30px 0 rgba(0,0,0,.1);
        margin-bottom: 50px;
        padding: 1rem;
        text-align: center;
      }

      img {
        margin-bottom: 20px;
      }
      .type {
        color: $TertiaryColor;
        font-family: $Font2SemiBold;
        font-size: 16px;
        letter-spacing: 0.2px;
        margin-bottom: 20px;
        text-transform: uppercase;
      }
      h3 {
        color: $PrimaryColor;
        font-family: $FontBold;
        font-size: 30px;
        font-stretch: normal;
        font-style: normal;
        //line-height: 0.97;
        line-height: 1;
        letter-spacing: normal;   
        margin-bottom: 16px;
        text-overflow: ellipsis;
        overflow: hidden;

         @include media-breakpoint-up(lg) {
          //white-space: nowrap;
        }
      }
      p {
        color: $SenaryColor; 
        margin-bottom: 10px;
      }
      .ByAuthor {
        color: $PrimaryColor;
        font-family: $Font2LightItalic;
      }
    }
    .WrapScroll {

      @include media-breakpoint-up(lg) {
        max-height: 650px;
        overflow-x: hidden;
      }

      @include media-breakpoint-up(xl) {
        max-height: 715px;
        overflow-x: hidden;
      }

      /* width */
      &::-webkit-scrollbar {
        border-radius: 5px;
        width: 6px;
        opacity: 0.49;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        border-radius: 5px;
        background: $White; 
        opacity: 0.49;
      }
       
      /* Handle */
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #b4adab; 
        opacity: 0.49;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #b4adab; 
      }

      .item {   
        margin-bottom: 40px;
        
        @include media-breakpoint-up(xl) {
          max-width: 257px;
        }

        img {
          margin-bottom: 20px;
        }
        .type {
          color: $TertiaryColor;
          font-family: $Font2SemiBold;
          font-size: 12px;
          letter-spacing: 0.15px;
          margin-bottom: 7px;
          text-transform: uppercase;
        }
        h3 {
          color: $PrimaryColor;
          font-family: $FontBold;
          font-size: 18px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.39;
          letter-spacing: normal;
          margin-bottom: 6px;
        }
        p {
          font-size: 14px;
          margin-bottom: 3px;
        }
        .ByAuthor {
          color: $PrimaryColor;
          font-family: $Font2LightItalic;
          font-size: 14.1px;
        }
      }
    }
  }
}




/*
=============================================
* Archive Popular Article Area
=============================================
*/
#PopularArticles2 {
  padding-top: 40px;

  @include media-breakpoint-up(lg) {
    padding-top: 70px;
  }

  .items {
    border-top: 1px solid $Septenary;
    padding-top: 50px;
    padding-bottom: 75px;


    @include media-breakpoint-down(lg) {
      padding-bottom: 50px;
      padding-top: 20px;
    }  
    
    .single-videos {
      border-top: 0 none;
      padding-top: 0;
    }

    @include media-breakpoint-up(lg) {
      .row {
        margin-left: -23px;
        margin-right: -23px;

        .col-lg-3 {
          padding-left: 23px;
          padding-right: 23px;
        }
      }
    }  

    h2 {
      padding-bottom: 50px;

      @include media-breakpoint-down(sm) {
        padding-bottom: 5px;
        text-align: center;
      }
    }

    .item {
      @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
      }
      @media (max-width: 575px) {
        box-shadow: 0 0 30px 0 rgba(0,0,0,.1);
        margin-bottom: 15px;
        padding: 1rem;
      }

      img {
        @media (max-width: 575px) {
          width: 100%;
        }
      }
    }

    h5 {
      margin-bottom: 15px;
      margin-top: 20px;
      text-align: left;
    }

    h3 {
      color: $PrimaryColor;
      font-family: $FontBold;
      font-size: 30px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.1;
      letter-spacing: normal;   
      margin-bottom: 12px;

      @include media-breakpoint-up(sm) {   
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;  
      }
    }
    p {
      color: $SenaryColor; 
      margin-bottom: 10px;
    }
    .ByAuthor {
      color: $PrimaryColor;
      font-family: $Font2LightItalic;
    }
  
  }
}
