/*
=============================================
* Ebook Area  
=============================================
*/
#Ebook {
  background-color: $QuinaryColor; 
  position: relative; 
  //border-top: 83px solid $White;
  overflow: hidden;

  @include media-breakpoint-up(xl) {
    padding: 70px 0;
  }

  @include media-breakpoint-down(lg) {
    //border-top: 50px solid $White;
  }

  .EbookImage {
    //background-color: $QuinaryColor; 

    .GrowingBook {
      @include media-breakpoint-up(xl) {
        position: absolute;
        top: -80px;
        overflow: hidden;  
      } 

      img {
        @include media-breakpoint-up(xl) {
          min-width: 842px;
        }  
      }
    }
  }

  .EbookInfo {
    @include media-breakpoint-up(xl) {
      padding: 90px 0;
    }

    @include media-breakpoint-down(lg) {
      position: relative;
      bottom: 35px;
    }

    @media (max-width: 480px) {
      bottom: 0;
    }

    h2 {
      font-family: $FontRegular; 
      font-size: 50px;
      font-weight: normal;  
      font-stretch: normal;
      font-style: normal;
      line-height: 0.92;
      letter-spacing: normal;
      color: $PrimaryColor;
      
      @include media-breakpoint-down(sm) {
        text-align: center;
      }   
    }

    h5 {
      font-family: $FontBold;
      font-size: 20px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: -0.4px;
      color: $TertiaryColor;
      margin-bottom: 40px;
      margin-top: 44px;

      @include media-breakpoint-down(sm) {
        text-align: center;
      }   
    }

    form {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      @include media-breakpoint-down(lg) {
        margin: 0 auto 70px;
      }   

      .input-group {
        @include media-breakpoint-up(xl) {
          width: 440px;
        }   
      }   

      @media (max-width: 480px) {
        input {
          &[type="email"] {   
            margin: 0 auto 15px;
            width: 100%;
          }
        }
      }

      .input-group-prepend {
        margin: 0 auto;
      }
    }
  }
}
