.wp-block-image {
    border: 1px solid $Septenary;
    //background-color: $White;
    border-radius: .25rem;
    clear: both;
    margin: 0 auto 1em;  
    padding: .25rem;
    text-align: center;

    figcaption {
    	color: $SenaryColor; 
		margin-bottom: 0.125rem;  
    }
}

.wp-block-embed {
	.wp-block-embed__wrapper {
		@extend .embed-responsive;
		@extend .embed-responsive-21by9;

		iframe {
			@extend .embed-responsive-item;
		}
	}
}

.wp-block-table {  
	@extend .table-responsive;

	table {
		@extend .table;
		@extend .table-bordered;
	}
}            	

.wp-block-quote,
blockquote {
	border-top: 1px solid rgba(151, 151, 151, 0.21);
	border-bottom: 1px solid rgba(151, 151, 151, 0.21);
	padding: 30px 50px 28px;
	margin-bottom: 50px;
	margin-top: 28px;

	p {
		font-family: $Font2MediumItalic;   
		font-size: 20px;
		font-weight: normal;  
		font-stretch: normal;
		line-height: 1.5 !important;
		letter-spacing: normal;
		text-align: center;
		color: $SenaryColor !important;
	}
}