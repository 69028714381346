﻿#Footer {
    background-image: 
        url(../images/leaf-1.png),
        url(../images/leaf-4.png),
        url(../images/leaf-3.png),
        url(../images/leaf-2.png),
        url(../images/leaf-2.png),
        url(../images/leaf-4.png),

        url(../images/leaf-1.png),
        url(../images/leaf-4.png),
        url(../images/leaf-3.png),
        url(../images/leaf-2.png),
        url(../images/leaf-2.png),
        url(../images/leaf-3.png);

    background-position:
        bottom left, 
        bottom left 140px,
        bottom left 420px,
        bottom left 965px, 
        bottom left 725px,  
        bottom left 1075px,

        bottom left 1440px,
        bottom left 1525px,
        bottom left 2220px, 
        bottom left 1830px,
        bottom left 2105px, 
        bottom left 2420px;    
    background-repeat: no-repeat; 
    background-color: $QuinaryColor;
    padding: 0 0 250px; 
    position: relative; 

    .SeedsCcontainer {
        position: relative;

        @media (max-width: 480px) {
            display: none;
        }
        .seeds1 {
            &:before {
                background-image: url(../images/seed-1@2x.png);
                background-size: contain;
                background-repeat: no-repeat;
                content: ''; 
                height: 87px;
                position: absolute;
                top: 75px;
                left: 145px;
                width: 76px;

                @include media-breakpoint-down(md) {
                    left: 20px;   
                }
            }
        }
        .seeds2 {
            &:before {
                background-image: url(../images/seed-2@2x.png);
                background-size: contain;
                background-repeat: no-repeat;
                content: ''; 
                height: 87px;
                left: 54px;
                position: absolute;
                width: 76px; 
                @include media-breakpoint-down(md) {   
                    left: -20px;
                }
            }
        }
    }
    .FooterLogo {
        margin-bottom: 24px; 
        position: relative;
        z-index: 1;

        img {
          width: 304px;
          height: 91px; 
        }
    }
    .SocialMedia {
        margin-bottom: 40px; 

        ul {
            li {
                margin: 0 9px; 

                a {
                    display: block;   
                    
                    .fa {
                        width: 36px; 

                        &:before {
                            font-size: 36px;
                            //height: 36px;
                            //width: 36px; 
                        }
                    }
                }
            }
        }
    }

    p {
        height: 26px;
        font-family: "Circe";
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #a39d94; 

        &:last-child {
            margin: 0; 
        }
    }
}


