#HomepageIntro {
  background-color: $QuinaryColor;
  background-image: url(../images/bg-hemp.png);
  background-position: left top;
  background-repeat: no-repeat;
  margin-bottom: 70px;
  margin-top: 75px;
  padding: 150px 0;
  position: relative;

  .menu-fixed & {
    //margin-top: 80px;
  }

  
  @include media-breakpoint-up(lg) {
    //margin-top: 107px;
    margin-top: 75px;
  }
  @include media-breakpoint-up(xl) {
    margin-top: 105px;
  }
  @include media-breakpoint-down(xl) {
    margin-bottom: 30px;
  }

  @include media-breakpoint-down(md) {
    background-size: 40%;
  }   

  h1 {
    color: $PrimaryColor;   
    font-family: $FontBold;
    font-size: 100px;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: -3.75px;
    margin-bottom: 40px;
  }

  p {
    color: $SenaryColor; 
    font-size: 22px;
    line-height: 1.5;
    margin-bottom: 36px;
  } 

  .container {
    position: relative;
  }

  .SeedsCcontainer {
    //position: relative;

    .seeds1 {
        &:before {
            background-image: url(../images/seed-1@2x.png);
            background-size: contain;
            content: ''; 
            height: 87px;
            position: absolute;
            bottom: 32%;
            right: 23px;
            width: 76px;

            @include media-breakpoint-down(sm) {
              bottom: -15%;
            }
        }
    }
    .seeds2 {
        &:before {
            background-image: url(../images/seed-2@2x.png);
            background-size: contain;
            bottom: 10%;
            content: ''; 
            height: 87px;
            right: 80px;
            position: absolute;
            width: 76px; 

            @include media-breakpoint-down(sm) {
              bottom: -30%;
            }
        }
    }
  }
}







/*
=============================================
* Accessories Area
=============================================
*/
#CategoryCarousel {
  background-color: $QuinaryColor;
  padding: 70px 0;
  background-image: url(../images/leaf-0.png);
  background-repeat: no-repeat;
  background-position: right top 15px;

  @include media-breakpoint-down(lg) {
    padding: 35px 0 0;  
  } 

  .owl-categories {
      padding-top: 50px;

      @include media-breakpoint-down(lg) {
       padding-top: 20px; 
      } 

    .item {
      img {
        margin-bottom: 35px;
      }

      h3 {
        color: $PrimaryColor;
        font-family: $FontBold;
        font-stretch: normal;
        font-weight: normal;
        font-style: normal;
        line-height: 1.1;
        letter-spacing: normal;
        margin-bottom: 12px;
      }

      p {
        color: $SenaryColor; 
      }
    }
  }
}


