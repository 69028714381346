.searchwp-live-search-widget-search-form {
	position: relative;

	label {
		display: block;
	}
	.search-field {
		width: 100%;
		font-family: $FontLight;
		font-size: 30px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: $PrimaryColor;
		border-bottom: solid 1px #dfdcd4;
		border-top: 0 none;
		border-left: 0 none;
		border-right: 0 none;

		&:hover, 
		&:focus {
			border-top: 0 none;
			border-left: 0 none;
			border-right: 0 none;
			outline: none;
		}
	}

	.search-submit {
		background-image: url(../images/ArrowRight.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-color: transparent;
		border: 0 none;
		color: transparent;
		position: absolute;
		right: 28px !important;
		top: 3px !important;
		height: 41px;
		width: 41px;
	}
}      

.searchwp-live-search-result p {
	padding: 0.5rem 1em;
}