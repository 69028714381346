.woocommerce-error, 
.woocommerce-info, 
.woocommerce-message {
	border-top-color: $SecondaryColor;

	@include media-breakpoint-down(sm) {
		padding: 1em 2em 1em 2em;
		margin: 1rem 0 2em;
		text-align: center;

		&:before {
			display: none;
		}

		.button {
			display: block;
		    width: 100%;
		    margin-bottom: 1rem !important;  
		}
	}
}
.woocommerce-error:before, 
.woocommerce-info:before, 
.woocommerce-message:before {
	color: $SecondaryColor;
}

.woocommerce a.remove {
	color: $TertiaryColor !important; 

	&:hover,
	&:focus {
		background-color: $TertiaryColor; 
	}
}

.woocommerce form .form-row .required { 
	color: $TertiaryColor;
}

.woocommerce form .form-row.woocommerce-invalid label  {
	color: $TertiaryColor;
	font-family: $Font2SemiBold;
}

.woocommerce form .form-row.woocommerce-invalid .select2-container, 
.woocommerce form .form-row.woocommerce-invalid input.input-text, 
.woocommerce form .form-row.woocommerce-invalid select {
	border-color: $TertiaryColor;
}

#add_payment_method table.cart td.actions .coupon .input-text, 
.woocommerce-cart table.cart td.actions .coupon .input-text, 
.woocommerce-checkout table.cart td.actions .coupon .input-text,
#add_payment_method table.cart input, 
.woocommerce-cart table.cart input, 
.woocommerce-checkout table.cart input {
	@extend .form-control;
	height: 51px;
	width: auto;

	@media (max-width: 519px)   {   
		width: 100%;
	}
}

#add_payment_method table.cart td.actions .coupon button, 
.woocommerce-cart table.cart td.actions .coupon button, 
.woocommerce-checkout table.cart td.actions .coupon button,
#add_payment_method table.cart button, 
.woocommerce-cart table.cart button, 
.woocommerce-checkout table.cart button {
	@media (max-width: 519px)   {   
		width: 100% !important;
		margin-top: 10px;
	}
}

#add_payment_method .wc-proceed-to-checkout a.checkout-button, 
.woocommerce-cart .wc-proceed-to-checkout a.checkout-button, 
.woocommerce-checkout .wc-proceed-to-checkout a.checkout-button,
.woocommerce #respond input#submit.alt, 
.woocommerce a.button.alt, 
.woocommerce button.button.alt, 
.woocommerce input.button.alt {
	@extend .btn;
	display: block;
}    

.woocommerce form .form-row input.input-text {
	@extend .form-control;
	height: 51px;
	width: 100%;
	box-shadow: none;
}
 
.woocommerce form .form-row textarea {
	@extend .form-control;
	width: auto;
	min-height: 158px;
	box-shadow: none;
}

.woocommerce form .form-row .woocommerce-input-wrapper, 
.woocommerce-page form .form-row .woocommerce-input-wrapper {
	width: 100%;
} 

.woocommerce form .form-row label, 
.woocommerce-page form .form-row label {
	width: 100%;
}

.select2-container--default .select2-selection--single {
	height: 51px;
    padding: 10px 5px;
    outline: 0;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
	top: 12px;
    right: 5px;
}


.checkout {
	h3 {
		margin-top: 2rem;
	}
}