.navigation {
    margin: 4rem 0 0;
	text-align: right;
}

.wp-paginate {
	border-bottom: 1px solid #dfdcd4;
    border-left: 0;
    display: inline-block;
    padding-bottom: 3px;

	li {
    	display: inline-block !important;
	    line-height: normal !important;
	    min-width: 60px;
	    text-align: center;
    	position: relative;
	}

	a,
	.page {
		background-color: transparent !important;
		border: 0 none !important;  
		font-size: 14px;
		padding: .5em;
		line-height: 1;
		display: block;
		color: #665953 !important;
    	text-align: center;
	}

	.current {
		&:after {
		    background-color: #665953;
		    border-radius: 3px;
		    bottom: -4px;
		    content: "";
		    display: inline-block;
		    height: 3px;
		    left: 0;
		    position: absolute;
		    width: 100%;
		}
	}
 
	.prev,
	.next {
	    font-size: 14px;
	}
}