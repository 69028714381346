
/*
* Shop Title Box 
*/
#TitleBox {
  //min-height: 285px;
  background-image: url(../images/banner-shop.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 70px;
  padding: 110px 0 90px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 40px;
    padding: 70px 0 55px;
  }

  h1 {
    color: $QuinaryColor;  
    font-size: 100px;
    font-family: $FontBold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.6;
    letter-spacing: normal;  

    @include media-breakpoint-down(sm) {
      font-size: 56px;
    }

    @media (max-width: 575px) {
      font-size: 45px;
    }
  }
}