/*
=============================================
* Category Carousel Blocks
=============================================
*/   
#CategoriesCarousel {   
  padding: 70px 0 140px; 
  background-color: $White;

  @include media-breakpoint-down(md) {
    padding: 70px 0 57;  
  } 

  .sticky {
    @include media-breakpoint-up(xl) {
      width:  662px;
    } 

    .wrap-item {
      @include transition(all 0.3s);
      bottom: 0;
      margin-bottom: 36px;
      position: relative;  
      text-align: left;  

      &:hover,
      &:focus {
        bottom: 12px;
      }

      @include media-breakpoint-up(xl) {
        width:  331px;
      }     

      &:before {
        background-color: rgba(0,0,0,0.28);  
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
      }

      .info  {
        bottom: 0;
        padding: 30px;
        position: absolute;  

        h3 {
          font-family: $FontBold;
          font-size: 30px;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.97;
          letter-spacing: normal; 
          margin-bottom: 12px;
        }
      }

      &.left {
        .info {
          h3 {
              color: $White;
              //color: $PrimaryColor;
          }
          p {
            color: $White;  
            //color: $SenaryColor;  
          }
        }
      }

      &.right .info h3,
      &.right .info p {
        color: $White;
      }
    }


    .owl-recipe-items {
      .owl-nav {
        display: none;
      }
    }
  }   

  .items {
    border: solid 1px $Septenary; 
    padding: 98px 38px 80px; 

    @include media-breakpoint-up(xl) {
        width:  451px;
    }

    .owl-carousel { 
      .owl-nav {
        position: relative;
        top: 0;

        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          height: 36px;
          width: 1.5px;
          background-color: #e2dfd8;
          top: 4px;
          left: 0;
          right: 0;  
          margin: 0 auto;
        }

        button {
          height: 42px;
          min-width: 42px;

          &.owl-prev {
            margin-right: 130px;

          }
        }
      }
    }

    .item {
      border-bottom: 1px solid $Septenary;
      margin-bottom: 20px;
      padding-bottom: 43px; 
      color: $SenaryColor;

      h2 {
        color: $PrimaryColor;   
        font-family: $FontBold;
        font-size: 45px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: 7px;
      }

      h5 {
        color: $TertiaryColor; 
        font-family: $Font2SemiBold;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        text-transform: uppercase;
      } 

      hr {
        margin-bottom: 20px;
        margin-top: 43px; 
      }

      p {
        color: $SenaryColor; 
        margin-bottom: 0; 
      }
    } 
  }
}

  