.variation-radios .variatioon_radio {
	@include transition(all 0.3s);
	position: relative;
    border: 1.5px solid $White;
    background: $White;
    width: 122px;
    height: 62px;
    margin-right: 1rem;
    border-radius: 3px;
    padding: 6px 0;

    @include media-breakpoint-down(lg) {
        width: 100px;
    }
    @include media-breakpoint-down(md) {
        width: 100%;
    }
} 
.variation-radios .variatioon_radio.active,
.variation-radios .variatioon_radio:hover,
.variation-radios .variatioon_radio:focus {
    border: 1.5px solid $SecondaryColor;   
}   
.variation-radios .variatioon_radio:last-child {
	margin-right: 0;
}          
.variation-radios .variatioon_radio input {
	cursor: pointer;   
	position: absolute;
    top: 0;
    bottom: 0;    
    width: 100%;
    height: 100%;
	opacity: 0;
}
.variation-radios .variatioon_radio label {
	color: $SecondaryColor;
    font-size: 16px;
    font-family: $Font2SemiBold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin: 0;
    width: 100%;
    display: block;
}
.variation-radios .variatioon_radio span {
	font-family: $Font2Regular;
	font-size: 14px;   
	color: $SecondaryColor;
    display: block;
    width: 100%;
    clear: both;
    line-height: normal;
    text-align: center;
}
.single_variation_wrap .woocommerce-variation {
	display: none !important;
}  


.woocommerce div.product .woocommerce-product-details__short-description {
  margin: 30px 0;   

  p {
    font-family: $FontRegular;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;  
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: $Octonary;
  }
}