

#main {
	background-color: $White;
	margin-top: 86px;

	@include media-breakpoint-up(lg) {
		margin-top: 110px;
	}

	.error404 & {  
	    background-color: $QuinaryColor;
	}
}         


// Single Post 
.single-post {
	#BannerPost {   
		background-color: $White;
		border-top: 1px solid rgba(151, 151, 151, 0.19);
		background-repeat: no-repeat;
    	background-position: right;
    	background-size: cover;
    	overflow: hidden;
		padding-top: 40px;
		padding-bottom: 70px;
		position: relative;

		@include media-breakpoint-down(md) {
			&:before {
				content: "";
				background-color: rgba(0,0,0,.35);
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
			}
		}


		@include media-breakpoint-up(lg) {
			background-image: none !important;
		}

		.container {
			position: relative;
			z-index: 99;
		}


		@include media-breakpoint-up(lg) {
			padding-top: 90px;
			padding-bottom: 150px;
		}

		@include media-breakpoint-up(xl) {
			padding-top: 100px;
			padding-bottom: 230px;
		}

		h5 {   
			text-align: left;
			margin-bottom: 30px;
    		text-transform: uppercase;
		}     
		h1 {
			font-family: $FontBold;
			font-size: 45px;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.02;
			letter-spacing: normal;
			color: $PrimaryColor;

			@include media-breakpoint-down(md) {
				color: $White;
			}
		}    
		.img {
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			bottom: 0;
			position: absolute;
			right: 0;
			text-align: right;
			top: 0;

			@include media-breakpoint-up(lg) {
				width: 50%;
			}

			@include media-breakpoint-down(md) {
				display: none;
			}

			img {
				width: 100%;
			}
		}  
		.TimetoRead {
			background-image: url(../images/time.png);
			background-repeat: no-repeat;
			background-position: top -5px left;
			padding-left: 40px;

			@include media-breakpoint-down(md) {
				color: $White;
			}
		}
	}

	#SecondContent {

		.item {
			color: $SenaryColor;
			padding: 70px 0;

			&:nth-child(even) {
				background-color: $QuinaryColor;
			}
			&:nth-child(odd) {
				background-color: $White;
			}    

			&:nth-child(2n) {
				background-image: url(../images/leaf-right.png);   
				background-repeat: no-repeat;
				background-position: left bottom;

				@include media-breakpoint-down(sm) {
					background-image: none;
				}
			}

			&:first-child {
				padding-top: 0;   

				&:before {  
					background-color: $QuinaryColor;
				    content: "";
				    height: 60%;
				    position: absolute;
				    width: 100%;
				    display: block;
				}

				.container {
					padding-top: 70px;
				}
			}

			h2 {
				&:first-child {
					margin-top: 0;
				}
			}

			figure {
				width: 100% !important;
			}

			img {
				margin-bottom: 25px;
				margin-top: 25px;
			    max-width: 100%;
			    height: auto;

			    @include media-breakpoint-up(lg) {  
			    	margin-bottom: 50px;
					margin-top: 50px;
			    }
			}

			ul {
				li {
					color: #f5693f;
					margin-bottom: 24px;
				}
			}


			&.two_column {
				position: relative;

				@include media-breakpoint-up(lg) {  
					&:after {
						background: rgba(183, 177, 175, 0.25);
						content: '';
						display: block;
						position: absolute;
						width: 1px;
						height: 100%;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						margin: 0 auto;
					}
				}

				p {
					line-height: 1.67;
				}
			}
		}
	}

	#main {
		background-color: $QuinaryColor;  

		.post-content {
			position: relative;

			@include media-breakpoint-up(lg) {
				background-image: url(../images/bg-seeds.png);
				background-position: left top 15%;
				background-repeat: no-repeat;
				background-size: auto;
			}
		}

		.wrap {
			padding-bottom: 70px;
			padding-top: 70px;
			position: relative;

			@include media-breakpoint-up(lg) {
				&:before {
		            background-image: url(../images/seed-1@2x.png);
		            background-size: contain;
		            content: ''; 
		            height: 87px;
		            position: absolute;  
		            top: 50%;
		            right: -3%;
		            width: 76px;

		            @include media-breakpoint-down(sm) {
		              bottom: -15%;
		            }
		        }

		        &:after {
		            background-image: url(../images/seed-2@2x.png);
		            background-size: contain;
		            top: 52%;
		            content: ''; 
		            height: 87px;
		            right: 5%;
		            position: absolute;
		            width: 76px; 

		            @include media-breakpoint-down(sm) {
		              bottom: -30%;
		            }
		        }   
	        }   

			.content {

				ol {
					li { 
						margin-bottom: 15px; 
					}
				}

				.entry-content {
					.first-letter {
						//font-size: 100px;
						//line-height: 1.05;
						//display: inline-block;
						//float: left;
						//height: 90px;
						//position: relative;
						//right: 10px;
					}
				}
			}
		}

		h2 {
			font-family: $FontBold;
			font-size: 45px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: normal;   
			color: $PrimaryColor;   
			margin-top: 20px;
			@include media-breakpoint-up(lg) {
    			margin-bottom: 30px;
    			margin-top: 50px;
			}

			@include media-breakpoint-down(sm) {
				font-size: 40px;
			}
		}
		h3 {
			font-family: $FontBold;  
			font-size: 30px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 0.97;
			letter-spacing: normal;
			color: $PrimaryColor;
			margin-top: 20px;
			@include media-breakpoint-up(lg) {
    			margin-bottom: 30px;
    			margin-top: 50px;
			}
		}
		p {	
			color: $PrimaryColor;
			line-height: 1.67;
		}
		b,
		strong {
			font-family: $FontBold;
			font-weight: normal;
		}
	}

	#RecommendProduct {
		background-color: $White;
		padding: 70px 0;
		text-align: center;

		@include media-breakpoint-down(sm) {
			padding: 40px 0 70px;
		}

		h2 {
			color: $PrimaryColor;
			font-family: $FontRegular;
			font-size: 40px;  
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;  
			line-height: 2;
			letter-spacing: normal;
			margin-top: 0;

			@include media-breakpoint-up(lg) {
				margin-bottom: 50px;
			}

			@include media-breakpoint-down(sm) {
				line-height: 1;
				margin-bottom: 25px;
			}
		}

		.items {
			@include media-breakpoint-up(lg) {
				margin: 0 auto;
				width: 900px;  

				.row {
					margin-left: -20px;
					margin-right: -20px;

					.col-lg-4 {
						padding-left: 20px;
						padding-right: 20px;
					}  
				}
			}

			.item {
				margin-bottom: 60px;
				text-align: center;

				.img {  
					background-color: $QuinaryColor;
					margin: 0 0 30px;
				}
				h3 {  
					color: $PrimaryColor;  
					font-family: $FontRegular;
					font-size: 30px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: normal;
					letter-spacing: normal;
					margin-bottom: 2px;
				}
				.price {
					color: $SecondaryColor;
					font-family: $FontBold;
					font-size: 20px;
					font-stretch: normal;
					font-style: normal;
					line-height: normal;
					letter-spacing: normal;
				}
			}
  
			.link_shop {
				color: $PrimaryColor;    
				border-bottom: 2px solid $SecondaryColor;
				font-family: $FontRegular;
				font-size: 16px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				font-size: 16px;
				letter-spacing: normal;
				margin-top: 20px;  
			}
		}
	}

	#CommentPost {
		background-color: $QuinaryColor;
		background-image: url(../images/left-000.png);
		background-repeat: no-repeat;
		background-size: auto;
		background-position: right top 20%;
		padding-bottom: 40px;
		padding-top: 40px;
  
		@include media-breakpoint-up(lg) {
			padding-bottom: 70px;
			padding-top: 70px;
		}
		@include media-breakpoint-down(sm) {
			background-image: none;
		}

		h2 {
			color: $PrimaryColor;
			font-family: $FontBold;
			font-size: 45px;
			font-stretch: normal;  
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			margin-bottom: 8px;
			text-align: center;
		}
		.comment-count {
			color: $PrimaryColor;
			font-family: $FontRegular;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
		}
		.wrap-comment-btn {
			margin-bottom: 45px;    
			margin-top: 20px;    
			text-align: center;

			.btn {
				color: $SecondaryColor;
				font-size: 15px;
				font-family: $FontBold;
				border: 2px solid $SecondaryColor;
				background: $QuinaryColor;
				text-transform: uppercase;
				border-radius: 2px;
				padding: 1px 0 0;
				height: 44px;
			}
		}
	}

	#RecommendPost {
		padding-top: 40px;
		text-align: left;

		@include media-breakpoint-up(lg) {
			padding-top: 70px;
		}

		h2 {
			color: $PrimaryColor;
			font-family: $FontRegular;
			font-size: 40px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 2;
			letter-spacing: normal;
			margin-bottom: 50px;
		}

		.items {
			@include media-breakpoint-up(lg) {
				.row {
					margin-left: -22px;
					margin-right: -22px;

					.col-lg-3 {   
						padding-left: 22px;
						padding-right: 22px;
					}  
				}
			}


			.item {
				@include media-breakpoint-down(lg) {
					margin-bottom: 2rem;
				}

				h5 {
					font-size: 14px;    
    				margin-top: 20px;
					text-align: left;
					text-transform: uppercase;
			  	}    
				h3 {
					color: $PrimaryColor;
					font-family: $FontBold;
					font-size: 30px;
					line-height: 1.1;


					@include media-breakpoint-up(lg) {
						text-overflow: ellipsis;
						overflow: hidden;
						white-space: nowrap;
					}
				}  
				p {
					color: $SenaryColor;
				}
				.ByAuthor {
					font-family: $Font2LightItalic;
					font-size: 14px;
				}
			}
		}
	}

	#OtherCategory {
		text-align: center;

		h2 {
			border-top: 1px solid #dfdcd4;
			color: $PrimaryColor;
			font-family: $FontRegular;
			font-size: 40px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 2;
			letter-spacing: normal;
			margin-bottom: 30px;
			margin-top: 40px;
			padding-top: 40px;

			@include media-breakpoint-up(lg) {
				margin-top: 70px;
			}
		}  

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				a {
					font-family: $Font2SemiBold;
					font-size: 16px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.38;
					letter-spacing: 0.2px;
					text-align: center;
					color: $SecondaryColor;
					text-transform: uppercase;
					margin-bottom: 12px;
				}
			}
		}
	}
}

// Single Video Page   
.single-videos {

	#main {
		background-color: $QuinaryColor;  

		.post-content {
			background-image: url(../images/bg-seeds.png);
			background-repeat: no-repeat;
			background-position: left top 15%;
			background-size: auto;
			position: relative;

			@media (max-width: 480px) {
				background: transparent;
			}
			@media (max-width: 575px) {
				background-position: left top 3%;
			}
		}

		.wrap {
			padding-bottom: 70px;
			position: relative;

			@include media-breakpoint-up(lg) {
				&:before {
		            background-image: url(../images/seed-1@2x.png);
		            background-size: contain;
		            content: ''; 
		            height: 87px;
		            position: absolute;  
		            top: 50%;
		            right: -3%;
		            width: 76px;
   
		            @include media-breakpoint-down(sm) {  
		              bottom: -15%;
		            }
		        }

		        &:after {
		            background-image: url(../images/seed-2@2x.png);
		            background-size: contain;
		            top: 52%;
		            content: ''; 
		            height: 87px;
		            right: 5%;
		            position: absolute;
		            width: 76px; 

		            @include media-breakpoint-down(sm) {
		              bottom: -30%;
		            }
		        }   
	        }   

			.content {

				.entry-content {
					.first-letter {
						//font-size: 100px;
						//line-height: 1.05;
						//display: inline-block;
						//float: left;
						//height: 90px;
						//position: relative;
						//right: 10px;
					}
				}
			}  
		}

		h2 {
			font-family: $FontBold;
			font-size: 45px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			color: $PrimaryColor;   
			margin-top: 20px;
			@include media-breakpoint-up(lg) {
    			margin-bottom: 30px;
    			margin-top: 50px;
			}
		}
		h3 {
			font-family: $FontBold;
			font-size: 30px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 0.97;
			letter-spacing: normal;
			color: $PrimaryColor;
			margin-top: 20px;
			@include media-breakpoint-up(lg) {
    			margin-bottom: 30px;
    			margin-top: 50px;
			}
		}
		p {	
			color: $PrimaryColor;
			line-height: 1.67;
		}
		b,
		strong {
			font-family: $FontBold;
			font-weight: normal;
		}
	}

	#RecommendProduct {
		background-color: $White;
		padding: 70px 0;
		text-align: center;

		@include media-breakpoint-down(sm) {
			padding: 40px 0 70px;
		}

		h2 {
			color: $PrimaryColor;
			font-family: $FontRegular;
			font-size: 40px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;  
			line-height: 2;
			letter-spacing: normal;
			margin-top: 0;

			@include media-breakpoint-up(lg) {
				margin-bottom: 50px;
			}
			@include media-breakpoint-down(sm) {
				line-height: 1;   
				margin-bottom: 25px;  
			}
		}

		.items {
			@include media-breakpoint-up(lg) {
				margin: 0 auto;
				width: 900px;

				.row {
					margin-left: -20px;
					margin-right: -20px;

					.col-lg-4 {
						padding-left: 20px;
						padding-right: 20px;
					}  
				}
			}

			.item {
				margin-bottom: 60px;
				text-align: center;

				.img {  
					background-color: $QuinaryColor;
					margin: 0 0 30px;
				}
				h3 {  
					color: $PrimaryColor;  
					font-family: $FontRegular;
					font-size: 30px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: normal;
					letter-spacing: normal;
					margin-bottom: 2px;
				}
				.price {
					color: $SecondaryColor;
					font-family: $FontBold;
					font-size: 20px;
					font-stretch: normal;
					font-style: normal;
					line-height: normal;
					letter-spacing: normal;
				}
			}
  
			.link_shop {
				color: $PrimaryColor;    
				border-bottom: 2px solid $SecondaryColor;
				font-family: $FontRegular;
				font-size: 16px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				font-size: 16px;
				letter-spacing: normal;
				margin-top: 20px;  
			}
		}
	}

	#CommentPost {
		background-color: $QuinaryColor;
		padding-bottom: 40px;
		padding-top: 40px;
		background-image: url(../images/left-000.png);
		background-repeat: no-repeat;
		background-size: auto;
		background-position: right top 20%;

		@include media-breakpoint-up(lg) {
			padding-bottom: 70px;
			padding-top: 70px;
		}
  
		@include media-breakpoint-down(sm) {
			background-image: none;
		}

		h2 {
			color: $PrimaryColor;
			font-family: $FontBold;
			font-size: 45px;
			font-stretch: normal;  
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			margin-bottom: 8px;
			text-align: center;
		}
		.comment-count {
			color: $PrimaryColor;
			font-family: $FontRegular;
			font-size: 16px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
		}
		.wrap-comment-btn {
			margin-bottom: 45px;    
			margin-top: 20px;    
			text-align: center;

			.btn {
				color: $SecondaryColor;
				font-size: 15px;
				font-family: $FontBold;
				border: 2px solid $SecondaryColor;
				background: $QuinaryColor;
				text-transform: uppercase;
				border-radius: 2px;
				padding: 1px 0 0;
				height: 44px;
			}
		}
	}
}