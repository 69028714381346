.sidebar {
	.widget {
		margin-bottom: 35px;
	}
	
	.widget-title {
		h3 {
			color: $PrimaryColor;
			font-family: $FontRegular;
			font-size: 30px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			margin-bottom: 20px;
		}
	}

	ul {
		list-style: none;
		padding-left: 0;

		li {
			color: $SenaryColor;
			font-family: $FontRegular;
			font-size: 18px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			margin-bottom: 12px;  
			padding-left: 25px !important;
			position: relative;

			a {
				color: $SenaryColor;

				&:after {
			        content: '';
			        width: 10px;
			        height: 10px;
			        object-fit: contain;
			        border: 1px solid $SenaryColor;
			        position: absolute;
			        border-radius: 50%;
			        left: 0;      
			        top: 7px;
			    }  

				&:hover,
				&:focus {
					color: $PrimaryColor;

					&:after {  
						background-color: $PrimaryColor;
			        	border: 1px solid $PrimaryColor;
					}
				}
			}

			ul {
				margin-top: 12px;
			}
		}
	}


	[role=search] {
		[type=search] {   
			border: 1px solid #dfdcd4;
		    height: 52px;
		    padding: 1rem;
		    width: 100%;
		}
		button {
			width: 100%;
    		margin-top: 0.5rem;
		}
	}
}