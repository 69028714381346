/*
* Pagination
*/
.woocommerce nav.woocommerce-pagination {
  text-align: right;
}
.woocommerce nav.woocommerce-pagination ul {
      border-bottom: 1px solid #dfdcd4;
      border-left: 0 none;
      border-right: 0 none;
      border-top: 0 none;
}
.woocommerce nav.woocommerce-pagination ul li {
  border-right: 0 none;
  overflow: visible;
  position: relative;
  text-align: center;
}   
.woocommerce nav.woocommerce-pagination ul li span {
  font-size: 14px;
  min-width: 60px;
}
.woocommerce nav.woocommerce-pagination ul li a {
  font-size: 14px;
  min-width: 60px;
}
.woocommerce nav.woocommerce-pagination ul li a:focus, 
.woocommerce nav.woocommerce-pagination ul li a:hover {
  background: none;
}  
.woocommerce nav.woocommerce-pagination ul li span.current {
  background: none;

  &:after {  
    background-color: $PrimaryColor;
    border-radius: 3px;
    bottom: -3px;
    content: '';
    display: inline-block;
    height: 3px;
    left: 0px;
    position: absolute;
    width: 100%;
  }
}