.mega-sub-menu {
	.wpaptw-posts-ul {
		padding: 0;

		li {
			border-bottom: 0 none;
			padding-bottom: 10px;
		}
		
		.wpaptw-thumbnail {
			display: none;
		}

		div.half_title {
			display: block;
			margin-left: 0;
			width: 100%;
		}
	}
}
