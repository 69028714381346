button,
.button,
.btn {
	@include border-radius(2px);
    background-color: $SecondaryColor; 
    border: 1px solid  $QuaternaryColor; 
    color: $White;  
    font-family: $FontBold;
    font-size: 15px;
    line-height: 40px;
    height: 51px;
    min-width: 223px;
    text-align: center;
    text-transform: uppercase;

    &:first-letter {
        text-transform: uppercase;
    }

    &:hover,
    &:focus {
        opacity: 0.9;
        background: $PrimaryColor;
        border-color: $PrimaryColor;
        color: $White;
    }   

    &.btn-primary {
        background: $SecondaryColor;
        border-color: $SecondaryColor;
    }

    &.btn-secondary {
        color: $White;
        //background: $TertiaryColor;
        //border-color: $TertiaryColor;
    }

    &.btn-tertiary {
        border-color: $White;
        color: $White;
    }
}

 