/*--------------------------------------------------------------
# Comments
--------------------------------------------------------------*/
#comments {
	margin-top: 40px;

	.comment-list {
	    @include list-unstyled;

	    .comment-item {
	    	margin-bottom: 40px;
  
	    	.comment-author {
	    		h4 {
					color: $PrimaryColor;
					font-family: $FontBold;
					font-size: 25px;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.16;
					letter-spacing: normal;
					margin-bottom: 12px;

					&:first-letter {
						text-transform: uppercase;
					}
	    		}
	    	}
	    	.comment-text {
	    		p {
					color: $PrimaryColor;
					font-family: $FontRegular;
					font-size: 18px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.56;
					letter-spacing: normal;
	    		}
	    	}
	    }

	    .comment {
	    	article {
	    		margin-bottom: 40px;

	    		.comment-author {
	    			color: $PrimaryColor;
					font-family: $FontBold;
					font-size: 25px;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.16;
					letter-spacing: normal;
					margin-bottom: 12px;

					&:first-letter {
						text-transform: uppercase;
					}

	    			.says {
	    				display: none;
	    			}  
	    		}
	    		.comment-metadata {
	    			a {
	    				display: none;
	    			}
	    		}
	    		.comment-content {
	    			p {
	    				color: $PrimaryColor;
						font-family: $FontRegular;
						font-size: 18px;
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.56;
						letter-spacing: normal;
	    			}
	    		}
	    		.edit-link {
	    			a {
	    				display: block;
	    			}  
	    		}
	    		.reply {
	    			display: none;
	    		}
	    	}

	    	.children {
    			bottom: 10px;
	   			list-style: none;
	   			position: relative;

	   			.comment {
	   				article {
	   					margin-bottom: 20px;
	   				}
	   			}
	    	}
	    }
	}

	.review + .children {
		bottom: 10px;
		position: relative;

		.comment {
			margin-bottom: 15px;
		}
	}
}
      
#respond {
	display: none;
	padding-top: 35px !important;

	.comment-reply-title {
		color: $SenaryColor;    
	    display: block;
	    margin: 0 0 10px;
	}

	.comment-notes {
		margin-bottom: 30px;
	}

	.comment-form-rating {
		display: inline-block;

		label {
			text-transform: uppercase;
		    color: $PrimaryColor;
		    font-family: $Font2SemiBold;
		    font-size: 15px;
		    letter-spacing: 2px;
		    margin-bottom: 15px;
		}
	}

	.comment-form-author {
		width: 33.3333%;
		display: inline-block;
		margin-right: 30px;
	}


	.comment-form-email {
		width: 33.3333%;
		display: inline-block;
		margin-right: 30px;
	}

	.comment-form-comment {
		margin-top: 40px;
	}

	input {
		opacity: 1;
			
		&[type=text],
		&[type=email] {   
			background: transparent;
			height: 44px;
			border-radius: 2px;
			border: 2px solid rgba(102, 89, 83, 0.32);
			padding: .375rem .75rem;
			width: 100%;

			&:focus {
				background: transparent;
				border: 2px solid $SecondaryColor;
				box-shadow: none;
				outline: 0;
			}
		}
	}

	textarea {
		background: transparent;
		height: 101px;
		border-radius: 2px;
		border: 2px solid rgba(102, 89, 83, 0.32);
		opacity: 1;
		padding: .375rem .75rem;
		width: 100%;

		&:focus {
			background: transparent;
			border: 2px solid $SecondaryColor;
			box-shadow: none;
			outline: 0;
		}
	}

	p {  
		&.stars {
			margin: 0;
		}

		label {   
			text-transform: uppercase;
		    color: $PrimaryColor;
		    display: block;
		    font-family: $Font2SemiBold;
		    font-size: 15px;
		    letter-spacing: 2px;   
		    margin-bottom: 20px;
		}
	}

	.form-submit  {
		border-bottom: 1px solid rgba(0,0,0,.1);
		margin-top: 50px;
	    padding-bottom: 60px;
		text-align: center;

		input,
		button {
			@extend .btn;
			@extend .btn-primary;
			@include border-radius(0 !important);
			background-color: $SecondaryColor !important;
			color:	$White !important; 
			text-transform: uppercase;
			font-size: 15px !important;
			font-weight: normal !important;
			font-family: $FontBold;
			padding: 2px 0 0 !important;
			text-transform: uppercase;

			&:hover,
			&:focus {
				background-color: $SecondaryColor !important;
				color: $White !important;
			}
		}
	}
}



