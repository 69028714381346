.bc-mnc.bc-mnc__float-none {
	display: inline-block;

	a {
		padding-left: 0 !important;
	}
}
#mega-menu-wrap-primary_navigation .bc-mnc {
	@include media-breakpoint-up(xl) {
		display: none;
	}
}
.bc-mnc .bc-mnc__cart-link--container {  
	@include media-breakpoint-only(lg) {
		top: 2px;  
	}
}
.bc-mnc .bc-mnc__cart-link--container.bc-mnc__cart-link-layout-01 .bc-mnc__cart-link--count-circle {
	background-color: transparent !important;
	color: #665953 !important;
    width: 16px !important;
    height: 16px !important;
    line-height: 16px !important;  
    font-size: 16px !important;
    right: -10px !important;
    top: 10px !important;

    @include media-breakpoint-down(sm) {
		color: $White !important;
	}
}
.bc-mnc .icon-cart-01:before {
	background-image: url(../images/cart-icon.png);
	background-repeat: no-repeat;  
	background-size: cover;
	content: '';
	width: 20px;
	height: 23px;

	@include media-breakpoint-down(md) {
		background-image: url(../images/cart-icon-white.png);
	}
}   
.bc-mnc .bc-mnc__cart-link {
	padding-left: 0;
} 

@include media-breakpoint-down(sm) {
	.bc-mnc.bc-mnc__float-none {
		display: block;
	}
}