// Grid system
.wrap {
  @include make-row();

	.content {
		@include make-col-ready();
		@include make-col($grid-columns);

		.sidebar-primary & {
			@include make-col($grid-columns);
			//@include make-col-offset($size, $columns: $grid-columns);
			
			@include media-breakpoint-up(xl) {
				@include make-col($grid-columns - $sidebar-xl-columns);
			}
			@include media-breakpoint-only(lg) {
				@include make-col($grid-columns - $sidebar-lg-columns);
			}

			@include media-breakpoint-up(xl) {
				padding-left: 40px;
			}
		}
	} 
 
	.sidebar { 
		@include make-col-ready();
		@include make-col($grid-columns);
  
		@include media-breakpoint-up(xl) {
			@include make-col($sidebar-xl-columns);
		}

		@include media-breakpoint-only(lg) {
			@include make-col($sidebar-lg-columns);
		}
	}  
}

