#ProductDetails {
	background-color: $QuinaryColor;

	//@media (min-width: 768px) {
	@include media-breakpoint-up(md) {
		padding-top: 20px;
	}

	div.product {
		form.cart {
			.reset_variations {
				.woocommerce & {
					display: none !important;
				}
			}
		}
	}
} 

.woocommerce div.product div.summary {
	margin: 0;
      
	@include media-breakpoint-up(lg) {
		padding-top: 50px;  
	}  
}
.woocommerce div.product div.images {
	margin-bottom: 0;
}
.woocommerce div.product .product_title {
	color: $PrimaryColor;
	font-family: $FontBold;  
	font-size: 40px;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
}
.woocommerce div.product .product_thc {
	font-family: $FontRegular;
	font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: $Octonary;
	text-transform: uppercase;
	margin-bottom: 15px;
}
.woocommerce div.product form.cart .variations td.label {
	display: none;
}
.woocommerce div.product form.cart .variations select {
	display: none;
}    
.woocommerce div.product form.cart .button {
	@extend .btn;
  	@extend .btn-primary;
	min-width: 247px;  
	height: 52px;
	object-fit: contain;
	font-size: 15px;
	text-transform: uppercase;

	@include media-breakpoint-down(sm) {
		width: 100%;
	}
}


#ProductFeatured {
	padding-bottom: 70px;
	padding-top: 30px;

	@include media-breakpoint-down(lg) {
		padding-top: 0;
	}

	@include media-breakpoint-down(sm) {
		padding-bottom: 30px;
	}

	hr {
		@include media-breakpoint-down(sm) {
			margin-top: 20px;
		}
	}

	h3 {
		color: $PrimaryColor;
		font-size: 40px;
		font-family: $FontRegular;
		font-stretch: normal;
		font-style: normal; 
		//line-height: 2;
		letter-spacing: normal;
		margin: 0 0 1rem;
		
		@include media-breakpoint-down(md) {
			margin: 2rem 0 1rem;
	    }
	}
	p {
		color: $SenaryColor;
		line-height: 1.56;
	}

	#accordion {
		.card {
			button {
				font-family: $FontRegular;
  				font-size: 20px;
  				height: auto;
				text-align: left;
				text-decoration: none;
    			padding: 14px 40px;
    			position: relative;

    			&:hover,
    			&:focus {
    				color: $White;
    			}

    			.fa {
					position: absolute;
					right: 40px;
					top: 50%;
					transform: translateY(-50%);

					 @include media-breakpoint-down(sm) {
						right: 20px;
				    }
    			}
			}
		}
	}	

	.table {
		tr {
			td {
				width: 50%;
  
				span {
					width: 5px;
					background: $PrimaryColor;
					border-radius: 50%;
					height: 5px;
					display: inline-block;
					position: relative;
					bottom: 3px;
					margin-right: 5px;
				}

				&:first-child {
					color: $PrimaryColor;
				}

				&:last-child {
					color: $SenaryColor;   
				}
			}
		}
	}

	hr {  
		border-top: 1px solid #dfdcd4;
		margin-bottom: 0;
		margin-top: 70px;
	}   
}

#ProductReviews {
	background-color: $QuinaryColor;
	background-image: url('../images/left-000.png');
	background-size: auto;
	background-repeat: no-repeat;
	background-position: top 20% right;
	padding-bottom: 70px;
	padding-top: 70px;
	margin-top: 70px;

	@include media-breakpoint-up(lg) {
		min-height: 500px;
	}

	h2 {
		color: $PrimaryColor;
		font-family: $FontBold;
		font-size: 45px;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
	}

	.comment-text {
		.meta {
			h4 {
				color: $PrimaryColor;
				font-family: $FontBold;
				font-size: 25px;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.16;
				letter-spacing: normal;
				margin-bottom: 7px;
  
				&:first-letter {
					text-transform: uppercase;
				}
			}   
		}
		.star-rating {
			float: none;
		    margin-bottom: 12px;
		    font-size: 19px;
		    letter-spacing: 5px;
		}
		.description {
			p {
				color: $PrimaryColor;
				line-height: 1.56;
			}
		}
	}
}

.related.products {
	padding-top: 75px;

	h2 {
		color: $PrimaryColor;
		font-family: $FontRegular;
		font-size: 40px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 2;
		letter-spacing: normal;
		margin-bottom: 50px;
	}

	.products {
		margin-bottom: 0;
	    border-bottom: 1px solid #dfdcd4;
	    padding-bottom: 60px;


		li.product {
			margin-bottom: 25px;
		}
	}
}


.woocommerce-notices-wrapper {
	.woocommerce-message {
		.button {
			@extend .btn;
		}
	}
}