/*
* Shop Ordering 
*/  
.SortBy {
  margin: 0 0 1rem;
}
.woocommerce .woocommerce-ordering {
  font-size: 20px;
  margin: 0;

  .bootstrap-select .dropdown-menu li {
    margin: .5rem 0;

    a {
      padding: .25rem 1.5rem .25rem 2.5rem;

      &:after {
        content: '';
        width: 10px;
        height: 10px;
        object-fit: contain;
        border: 1px solid $SenaryColor;
        position: absolute;
        border-radius: 50%;
        left: 17px;
        top: 11px;
      }

      &:hover,
      &:focus {
        background-color: transparent;  
        color: $PrimaryColor;

        &:after {
          background-color: $PrimaryColor;
          border: 1px solid $PrimaryColor;
        }
      }
    }
  }

  .btn {
    background: transparent;
    border: 0 none;  
    color: $SenaryColor;
    text-transform: none;
    font-family: $FontRegular;
    font-size: 20px;

    &:focus {
      outline: 0 !important;
      border: 0 none;
      box-shadow: none; 
    }
  }

  select {
    border: 0 none;
    color: $SenaryColor;
    font-family: $FontRegular;

    &:focus {
      outline: 0;   
    }
  }

  div.dropdown-menu {
    padding: 0;

    &.show {
      border: 0 none;
      box-shadow: 0 5px 24px 0 rgba(0, 0, 0, 0.1);
      padding: 0.75rem 0 0;
    }


    li {
      &:last-child {
        margin-bottom: 0;
      }

      a {
        font-family: $FontRegular;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $SenaryColor;

        &.dropdown-item {  

          &.active {
            background-color: transparent;  
            //color: #665953;
            color: $SenaryColor;
          }
        }
      }
    }
  }
}  