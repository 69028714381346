#GuidanceBox {
	background-color: $White;
	color: $SenaryColor;
	margin-bottom: 70px;
	//margin-top: 70px;
	padding-top: 50px;
	text-align: center;


	@include media-breakpoint-down(sm) {
		padding-top: 30px;
	}

	.blog &,
	.archive & {
		margin-top: 0;
	}

	.page &,
	.single-videos &,
	.post-type-archive-videos & {
		margin-top: 0;
	}

	.item {
		border-top: 1px solid #dfdcd4;

		.single-product & {
			border-top: 0 none;
		}

		.post-type-archive-product & {
	      	margin-top: 50px;

	      	@include media-breakpoint-up(lg) {
	        margin-top: 70px;
	      }   
		}
	}

	h2 {
		margin-top: 12px;
	}

	p {	
		text-align: center;
   		text-align-last: center;
	}

	a.btn {
		margin-top: 40px;
	}
}  