/*--------------------------------------------------------------
# WordPress Generated Classes
# http://codex.wordpress.org/CSS#WordPress_Generated_Classes
--------------------------------------------------------------*/


// Captions 
.wp-caption {
  @extend .thumbnail !optional;
}
.wp-caption-text { 
  padding: $thumbnail-padding;
} 

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only !optional;
  @extend .sr-only-focusable !optional;
}
