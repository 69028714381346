/*
=============================================
* Category Weirdness Area
=============================================
*/
#CategoryWeirdness {
  padding: 70px 0 78px;
  background-color: $White;

  @include media-breakpoint-down(md) {
    padding: 0 0 70px;  
  } 

  .item {
    display: inline-block;  
    position: relative;
    text-align: center;   

    h3 {
      color: $White;
      display: block;
      font-family: $FontBold;   
      font-size: 45px;
      position: absolute;
      top: 50%;    
      transform: translateY(-50%);
      width: 100%;
      z-index: 2;
    }

    a {
      display: block;   
      color: $White;

      &:before {
        background-color: rgba(0,0,0,0.25);
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;

        @include media-breakpoint-down(sm) {
          background-color: rgba(0,0,0,0.45);
        } 
      }

      &.left,
      &.right {
        &:before {
          background-color: rgba(0,0,0,0);
          bottom: auto; 
          top: auto;
        }
      }

      h3 {
        @include media-breakpoint-down(sm) {
          font-size: 32px;
        } 

        i {
          @include transition(all 0.3s);
          background-repeat: no-repeat;
          background-size: auto;    
          background-position: center;
          display: inline-block;    
          height: 30px;
          width: 0;
          opacity: 0;
          //position: absolute;   
          top: 12px;

          &.arrow-right {    
            background-image: url(../images/ArrowRight-White.png);
            //right: 15%;
          }
          &.arrow-left {
            background-image: url(../images/ArrowLeft-White.png);
            //left: 15%;
          }
        }

        &:hover,
        &:focus {
          i {
            opacity: 1;
            width: 35px;
          }
        }
      }
    }
  }

  p {
    color: $SenaryColor; 
  }
}
  