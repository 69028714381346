#GuaranteeBox {
  border-bottom: 1px solid #dfdcd4;
  margin-bottom: 40px;
  padding-bottom: 75px;

  @include media-breakpoint-down(md) {
    .item {
      margin: 0 0 20px;
    }
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: 30px;
  }

  .img {
    height: 60px;
    margin-right: 25px;
    width: 60px;

    @include media-breakpoint-down(md) {
      margin-right: 15px;
    }
  }

  h5 {
    color: $PrimaryColor;   
    font-family: $FontBold;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;

    @include media-breakpoint-down(md) {
      margin-bottom: 5px;
    }
  }

  p {
    color: $PrimaryColor;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
  }

  .main-text {
    p {
      color: $SenaryColor;  
      font-size: 18px;
      line-height: normal;
      margin-bottom: 45px;
    }
  }
}